import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { useState, useMemo, useEffect } from 'react';
import { CANDIDATEPROJECT_STATUS, CANDIDATEPROJECT_STATUS_UPDATED_TEXT } from 'constants/candidate_project';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

export default function SideBarProjectTable({
  children,
  candidateCountStatus,
  statusOfProject,
  setStatusOfProject,
  setIsSideBarOpen,
  setLoading,
}) {
  const [menuClass, setMenuClass] = useState('open');
  const [open, setOpen] = useState(true);
  const [stages, setStages] = useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [totalCount, setTotalCount] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1179));
  const [currentStatus, setCurrentStatus] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const stages = Object.entries(candidateCountStatus).map((entry) => ({ name: entry[0], quantity: entry[1] }));
    const allStagesKey = stages.map((stage) => CANDIDATEPROJECT_STATUS[`${stage.name}`]);
    let totalCount = 0;
    stages.forEach((stage) => {
      totalCount += stage.quantity;
    });
    setLoading(true);
    setStatusOfProject(allStagesKey);
    setTotalCount(totalCount);
    setCurrentStatus(`ALL`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    if (isMobile) return setOpen(false);
  }, [isMobile]);

  useEffect(() => {
    setShow((show) => !show);
  }, [statusOfProject]);

  useEffect(() => {
    let totalCount = 0;
    stages?.forEach((stage) => {
      totalCount += stage.quantity;
    });
    setTotalCount(totalCount);
  }, [stages]);

  useMemo(() => {
    setStages(Object.entries(candidateCountStatus).map((entry) => ({ name: entry[0], quantity: entry[1] })));
  }, [candidateCountStatus]);

  useEffect(() => {
    setIsSideBarOpen(open);
    if (open) return setMenuClass('open');
    setMenuClass('close');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleMenuClick = (stage) => {
    const keyValue = CANDIDATEPROJECT_STATUS[`${stage.name}`];
    setStatusOfProject([keyValue]);
    setCurrentStatus(stage.name.replaceAll(/_/g, ' ') + ` (${stage.quantity})`);
  };
  const handleMenuAll = () => {
    const stages = Object.entries(candidateCountStatus).map((entry) => ({ name: entry[0], quantity: entry[1] }));
    const allStagesKey = stages.map((stage) => CANDIDATEPROJECT_STATUS[`${stage.name}`]);
    setStatusOfProject(allStagesKey);
    setCurrentStatus(`ALL`);
  };

  useMemo(() => {
    if (menuClass === 'close' && isMobile) {
      setTimeout(() => {
        setMenuClass(`${menuClass} throwBack`);
      }, 300);
    }
  }, [menuClass, isMobile]);

  return isMobile ? (
    <>
      <Box
        className={menuClass}
        sx={{
          width: '100%',
          height: '100%',
          position: 'fixed',
          background: 'rgba(0,0,0,.8)',
          left: '0',
          top: '0',
          '&.open': {
            zIndex: '9999',
            opacity: '1',
            transition: '.2s all ease-in',
          },
          '&.close': {
            zIndex: '9999',
            opacity: 0,
            transition: '.2s all ease-in',
            '&.throwBack': {
              zIndex: 'unset',
            },
          },
        }}
      >
        <Box
          className={menuClass}
          sx={{
            position: 'fixed',
            width: '80%',
            maxWidth: '350px',
            background: 'white',
            height: '100%',
            '&.close': {
              transform: 'translateX(-100%)',
              transition: '.4s all ease',
            },
            '&.open': {
              transform: 'translateX(0%)',
              transition: '.4s all ease',
            },
          }}
        >
          <Box sx={{ background: theme.palette.gradients.dark.lr }}>
            <Typography
              variant="title"
              align="center"
              sx={{
                color: 'white',
                display: 'inline-block',
                paddingLeft: '20px',
                marginBottom: '1em',
                marginTop: '1em',
              }}
            >
              Select Stage
            </Typography>
          </Box>
          <MenuList sx={{ paddingTop: '20px' }}>
            <MenuItem
              key={'all'}
              selected={0 === selectedIndex}
              sx={{
                '&.Mui-selected': { background: theme.palette.nexxusBlue.main, '& .MuiTypography-root': { color: 'white' } },
              }}
              onClick={(event) => {
                handleMenuItemClick(event, 0);
                handleMenuAll();
              }}
            >
              <Typography
                variant="subtitle"
                align="center"
                sx={{
                  color: 'black',
                  display: 'inline-block',
                  paddingLeft: '15px',
                  marginBottom: '.5em',
                  marginTop: '.5em',
                }}
              >
                All ({totalCount})
              </Typography>
            </MenuItem>
            {stages &&
              stages.map((stage, index) => (
                <MenuItem
                  key={stage.name}
                  selected={index + 1 === selectedIndex}
                  sx={{
                    '&.Mui-selected': {
                      background: theme.palette.nexxusBlue.main,
                      '& .MuiTypography-root': { color: 'white' },
                    },
                  }}
                  onClick={(event) => {
                    handleMenuItemClick(event, index + 1);
                    handleMenuClick(stage);
                  }}
                >
                  <Typography
                    variant="subtitle"
                    align="center"
                    sx={{
                      color: 'black',
                      display: 'inline-block',
                      paddingLeft: '15px',
                      marginBottom: '.5em',
                      marginTop: '.5em',
                    }}
                  >
                    {CANDIDATEPROJECT_STATUS_UPDATED_TEXT[stage.name]} ({stage.quantity})
                  </Typography>
                </MenuItem>
              ))}
          </MenuList>
          <IconButton
            aria-label=""
            onClick={() => setOpen(false)}
            sx={{ zIndex: '999', position: 'absolute', top: '0', left: '100%', '& .MuiSvgIcon-root': { color: 'white' } }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <div style={{ overflow: 'hidden' }}>
        <Box
          style={{
            width: '100%',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            background: 'linear-gradient(90deg, #004dd3 0%, #011c2e 100%)',
          }}
        >
          <IconButton
            aria-label=""
            onClick={() => setOpen(true)}
            sx={{ zIndex: '999', marginLeft: '10px', '& .MuiSvgIcon-root': { color: 'white', fontSize: '1.2em' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="subtitle"
            align="center"
            sx={{
              color: 'white',
              display: 'inline-block',
              paddingLeft: '10px',
            }}
          >
            CANDIDATE PIPELINE
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <>{children}</>
        </Stack>
      </div>
    </>
  ) : (
    <div style={{ overflow: 'hidden' }}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          background: 'linear-gradient(90deg, #004dd3 0%, #011c2e 100%)',
          borderTopRightRadius: '12px',
          borderTopLeftRadius: '12px',
        }}
      >
        <IconButton
          aria-label=""
          onClick={() => setOpen(!open)}
          sx={{ zIndex: '999', marginLeft: '14px', '& .MuiSvgIcon-root': { color: 'white', fontSize: '1.2em' } }}
        >
          {open ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <Typography color="white" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
          {currentStatus}
        </Typography>
      </Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{ '& .MuiStack-root>:not(style)+:not(style)': { marginLeft: '0px!important' } }}
      >
        <MenuList
          className={menuClass}
          sx={{
            zIndex: '1',
            background: 'white',
            borderLeft: '1px solid #E6E6E6',
            borderRight: '1px solid #E6E6E6',
            borderBottom: '1px solid #E6E6E6',
            borderBottomLeftRadius: '16px',
            minWidth: '257px',
            width: '25%',
            '&.close': {
              '@media (max-width:1548px)': {
                marginLeft: '-257px!important',
              },
              marginLeft: '-25%',
              transition: '.5s all ease',
            },
            '&.open': {
              marginLeft: '0px',
              transition: '.5s all ease',
            },
          }}
        >
          <MenuItem
            key={'all'}
            selected={0 === selectedIndex}
            sx={{
              '&.Mui-selected': { background: theme.palette.nexxusBlue.main, '& .MuiTypography-root': { color: 'white' } },
            }}
            onClick={(event) => {
              handleMenuItemClick(event, 0);
              handleMenuAll();
            }}
          >
            <Typography
              variant="subtitle"
              align="left"
              sx={{
                color: 'black',
                display: 'inline-block',
                paddingLeft: '15px',
                marginBottom: '.5em',
                marginTop: '.5em',
                whiteSpace: 'break-spaces',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              All ({totalCount})
            </Typography>
          </MenuItem>
          {stages &&
            stages.map((stage, index) => (
              <MenuItem
                key={stage.name}
                selected={index + 1 === selectedIndex}
                sx={{
                  '&.Mui-selected': {
                    background: theme.palette.nexxusBlue.main,
                    '& .MuiTypography-root': { color: 'white' },
                  },
                }}
                onClick={(event) => {
                  handleMenuItemClick(event, index + 1);
                  handleMenuClick(stage);
                }}
              >
                <Typography
                  variant="subtitle"
                  align="left"
                  sx={{
                    color: 'black',
                    display: 'inline-block',
                    paddingLeft: '15px',
                    marginBottom: '.5em',
                    marginTop: '.5em',
                    whiteSpace: 'break-spaces',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {CANDIDATEPROJECT_STATUS_UPDATED_TEXT[stage.name]} ({stage.quantity})
                </Typography>
              </MenuItem>
            ))}
        </MenuList>
        <Fade show={show}>{children}</Fade>
      </Stack>
    </div>
  );
}

const Fade = ({ show, children }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(true);
  };

  return (
    shouldRender && (
      <div
        style={{
          width: '100%',
          height: 'auto',
          zIndex: '0',
          margin: '0px',
          display: 'flex',
          animation: `${show ? 'fadeIn' : 'fadeOut'} .8s`,
        }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  );
};
