export function RequiredMessages() {
  return {
    'string.base': `Field required`,
    'string.empty': `Field required`,
    'any.required': `Field required`,
  };
}
export function NumberMessages() {
  return {
    'number.base': `Field required`,
    'any.required': `Field required`,
    'any.only': `Field required`,
    'number.positive': `Field must be a positive number`,
    'number.min': `Field must be min {#limit} digits`,
    'number.max': `Field must be max {#limit} digits`,
  };
}
export function StringMessages() {
  return {
    'string.base': `Field required`,
    'string.empty': `Field required`,
    'string.min': `Field must be min {#limit} characters`,
    'string.max': `Field must be max {#limit} characters`,
    'string.email': `Field must be a valid email`,
    'string.length': `Field must be a number of {#limit} characters`,
    'string.pattern.base': `Field must be a valid with pattern {#regex}`,
  };
}

export function UriMessages() {
  return {
    'string.uri': `Field must be a valid URL`,
  };
}

export function RefMessages() {
  return {
    'any.only': `Field must be match`,
  };
}

export function ArrayMessages() {
  return {
    'array.base': `Field required`,
    'array.min': `At least one element is required`,
  };
}

export function AnyMessages() {
  return {
    'any.only': `Field required`,
    'any.required': 'Field required',
    'any.invalid': 'Field required',
  };
}

export function setValidationMessages(dataType) {
  let messages = {};
  dataType.map((type) => {
    if (type === 'string') messages = { ...messages, ...StringMessages() };
    if (type === 'required') messages = { ...messages, ...RequiredMessages() };
    if (type === 'number') messages = { ...messages, ...NumberMessages() };
    if (type === 'ref') messages = { ...messages, ...RefMessages() };
    if (type === 'uri') messages = { ...messages, ...UriMessages() };
    if (type === 'array') messages = { ...messages, ...ArrayMessages() };
    if (type === 'any') messages = { ...messages, ...AnyMessages() };

    return messages;
  });

  return Object.assign({}, messages);
}
