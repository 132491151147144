import MainLayout from '../../../components/MainLayoutRedesign/TasMainLayout';
import { connect } from 'react-redux';
import { loginExpire } from '../../../store/auth';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';

import { Box } from '@mui/material';
import TagsPageContent from './TagsPageContent';
import { useEffect } from 'react';

const TagsPage = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Tags',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="administration">
      <Box pt={2} pb={2}>
        <TagsPageContent />
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagsPage);
