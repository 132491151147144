import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import RateReviewIcon from '@mui/icons-material/RateReview';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import TabFeedback from 'pages/tas/candidateTabs/tabFeedback';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import TabInterview from './NewTabInterview';
import TabProjects from './NewTabProjects';
import TabSkills from './NewTabSkills';
import TabHistory from './tabHistory';
import TabUploads from './tabUploads';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const CustomTabs = styled(Tabs)({
  backgroundColor: '#eeeeee',
  '& .MuiTabs-indicator': {
    backgroundColor: 'nexxusBlue.main',
    height: 3,
  },
  '& .MuiTab-root.Mui-selected': {
    color: 'nexxusBlue.main',
  },
  '& .MuiTab-root': {
    borderBottomStyle: 'solid',
    borderWidth: '2px',
    borderColor: '#e5e5e5',
    backgroundColor: '#fff',
    flexDirection: 'row',
    minHeight: '50px',
    '& > svg': {
      marginRight: '10px',
    },
    '& :not(.Mui-selected)': {
      borderRightStyle: 'none!important',
    },
  },
  '& .Mui-selected': {
    backgroundColor: '#fff',
    borderTopStyle: 'none',
    borderRightStyle: 'solid',
    borderBottomStyle: 'none',
    borderLeftStyle: 'solid',
    borderWidth: '2px',
    borderColor: '#e5e5e5',
  },
});

const CandidateTabs = ({ candidateId, skills, isDisabled, activeCandidate, setHasChanged, hasChanged }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', my: 5, mx: 0 }}>
      <CustomTabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        scrollButtons={false}
        TabIndicatorProps={{ sx: { top: 0 } }}
      >
        <Tab label={'Skills'} icon={<CodeOffIcon />} {...a11yProps(0)} />
        <Tab icon={<WorkOutlineOutlinedIcon />} label="Projects" {...a11yProps(1)} />
        <Tab icon={<BarChartOutlinedIcon />} label="Interview" {...a11yProps(2)} />
        <Tab icon={<FolderOpenOutlinedIcon />} label="Uploads" {...a11yProps(3)} />
        <Tab icon={<HistoryOutlinedIcon />} label="History Log" {...a11yProps(4)} />
        <Tab icon={<RateReviewIcon />} label="Feedback" {...a11yProps(4)} />
      </CustomTabs>

      <SwipeableViews
        style={{
          // height: '500px',
          borderTopStyle: 'none',
          borderRightStyle: 'solid',
          borderBottomStyle: 'solid',
          borderLeftStyle: 'solid',
          borderBottomLeftRadius: theme?.components?.MuiDataGrid?.styleOverrides?.root?.borderRadius,
          borderBottomRightRadius: theme?.components?.MuiDataGrid?.styleOverrides?.root?.borderRadius,
          borderWidth: '2px',
          borderColor: '#e5e5e5',
        }}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0}>
          <TabSkills
            setHasChanged={setHasChanged}
            candidateId={candidateId}
            initialSkills={skills}
            isDisabled={isDisabled}
          ></TabSkills>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <TabProjects candidateId={candidateId} activeCandidate={activeCandidate}></TabProjects>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <TabInterview
            setHasChanged={setHasChanged}
            candidateId={parseInt(candidateId)}
            isDisabled={isDisabled}
          ></TabInterview>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <TabUploads
            setHasChanged={setHasChanged}
            candidateId={candidateId}
            isDisabled={isDisabled}
            hasChanged={hasChanged}
          ></TabUploads>
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <TabHistory candidateId={candidateId}></TabHistory>
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <TabFeedback candidateId={candidateId}></TabFeedback>
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};

export default CandidateTabs;
