import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../../components/MainLayoutRedesign/TasMainLayout';

import ClientFormLayout from './ClientFormLayout';

import { ROLE_SUPERTAS } from 'constants/users';
import { connect } from 'react-redux';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import { loginExpire } from '../../../store/auth';

const CreateClient = (props) => {
  const { modeCustomer } = useParams();
  const history = useNavigate();

  useEffect(() => {
    const {
      auth: { user },
    } = props;
    if (modeCustomer === 'edit' && ![ROLE_SUPERTAS].includes(user.role) === true) return history('/403');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeCustomer]);

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
        url: '/tas/dashboard',
      },
      {
        name: 'Customers',
        url: '/tas/customers',
      },
      {
        name: modeCustomer === 'edit' ? 'Edit Customer' : 'Create Customer',
      },
    ]);
  }, [modeCustomer, setBreadcrumbs]);

  return (
    <MainLayout {...props} current="customers" subtitle="Create Customer">
      <ClientFormLayout submitButtonText="CREATE CUSTOMER" />
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClient);
