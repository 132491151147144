import { CloudUpload as UploadIcon } from '@mui/icons-material';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import { Avatar, Box, Button, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { createRef, useEffect, useRef } from 'react';
import { isEmptyString, stringAvatar } from 'utils/utils';

const Overlay = styled(Box)({
  position: 'absolute',
  width: '116px',
  height: '116px',
  backgroundColor: 'rgba(0, 0, 0, .7)',
  borderRadius: '50%',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  opacity: 0,
  transition: 'opacity .3s ease-in-out',
  '&:hover': {
    opacity: 1,
  },
});

const UploadButton = styled(Button)(() => ({
  color: 'white',
  flexFlow: 'column',
  fontWeight: 'bold',
  fontSize: '10px',
  padding: '2px',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  color: theme?.palette?.nexxusRed?.main,
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '10px',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const InputProfileImage = (props) => {
  const dropRef = useRef(null);
  let inputFileElement = createRef(null);
  let dropCounter = useRef(0); /* eslint-disable-line */

  let avatarProps = {
    src: props.avatar,
  };

  if (!props.isCompany && props?.name) avatarProps = _.merge(avatarProps, stringAvatar(props.name));
  if (props.isCompany) avatarProps.children = <ApartmentRoundedIcon sx={{ fontSize: 60 }} />;

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dropCounter++;
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dropCounter--;
  };
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    inputFileElement.value = '';
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      props.onHandleDrop(e.dataTransfer.files);
      dropCounter = 0;
    }
  };

  useEffect(() => {
    let dropable = dropRef.current;
    if (props.isDisabled && props.isDisabled === true) {
      if (dropable) {
        dropable.addEventListener('dragenter', handleDragIn);
        dropable.addEventListener('dragleave', handleDragOut);
        dropable.addEventListener('dragover', handleDrag);
        dropable.addEventListener('drop', handleDrop);
        return () => {
          dropable.removeEventListener('dragenter', handleDragIn);
          dropable.removeEventListener('dragleave', handleDragOut);
          dropable.removeEventListener('dragover', handleDrag);
          dropable.removeEventListener('drop', handleDrop);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let dropable = dropRef.current;
    if (props.isDisabled && props.isDisabled === true) {
      if (dropable) {
        dropable.addEventListener('dragenter', handleDragIn);
        dropable.addEventListener('dragleave', handleDragOut);
        dropable.addEventListener('dragover', handleDrag);
        dropable.addEventListener('drop', handleDrop);
        return () => {
          dropable.removeEventListener('dragenter', handleDragIn);
          dropable.removeEventListener('dragleave', handleDragOut);
          dropable.removeEventListener('dragover', handleDrag);
          dropable.removeEventListener('drop', handleDrop);
        };
      }
    }
  }, [props.isDisabled]); // eslint-disable-line

  return (
    <>
      <FormControl>
        <Box sx={{ position: 'relative' }}>
          {props.isDisabled === false && (
            <Overlay ref={dropRef} invisible={'false'} sx={{ ...props.styleAvatar }}>
              <UploadButton variant="text" component="label">
                <UploadIcon />
                UPLOAD PHOTO
                <input
                  ref={(input) => (inputFileElement = input)}
                  type="file"
                  name="files"
                  accept={props.accept?.join(',') || 'image/jpeg,image/png,image/gif'}
                  onClick={() => (inputFileElement.value = '')}
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      e.target.files = null;
                      return props.onHandleDrop(e.target.files);
                    }
                  }}
                  hidden
                />
              </UploadButton>
              <DeleteButton
                onClick={(e) => {
                  props.onHandleDelete(e);
                }}
              >
                DELETE PHOTO
              </DeleteButton>
            </Overlay>
          )}
          <Avatar
            {...avatarProps}
            sx={{
              width: '116px',
              height: '116px',
              fontSize: '3.3rem',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: 'rgba(255, 255, 255, .6)',
              border: '1px solid rgba(0, 0, 0, .4)',
              bgcolor: 'nexxusGrayLight.main',
              ...props.styleAvatar,
            }}
          />
        </Box>
      </FormControl>
    </>
  );
};

InputProfileImage.propTypes = {
  isCompany: PropTypes.bool,
  name: function (props, propName, componentName) {
    if (!props['isCompany'] && isEmptyString(props['name'])) {
      return new Error(`"${propName}" is required on "${componentName}" when "isCompany" prop is set to false.`);
    }
  },
  accept: PropTypes.array,
  avatar: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onHandleDrop: PropTypes.func,
  onHandleDelete: PropTypes.func,
};

export default InputProfileImage;
