import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';

import { FiberManualRecord } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ROLE_SUPERTAS } from 'constants/users';
import { connect } from 'react-redux';
import { capitalizeString } from 'utils/utils';
import { loginExpire } from '../../../store/auth';
import { PROJECT_STATUS } from 'constants/project';
import { amber } from '@mui/material/colors';
import ReportXls from './ReportXls';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { POSITIONS } from '@nexxus/constants';

const NoDataOverlay = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
        NO DATA
      </Typography>
      <Typography variant="h6" color="nexxusGrayLight.main">
        Please Select Candidate Fields
      </Typography>
    </Stack>
  );
};

const RenderActions = (props) => {
  const { row } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const handleViewProject = (ev) => {
    ev.preventDefault();
    navigate(`/tas/projects/${row.id}/view`);
  };
  const handleDuplicateProject = (ev) => {
    ev.preventDefault();
    navigate(`/tas/projects/create/based_project/${row.id}`);
  };

  return (
    <Stack direction="row" spacing={1}>
      <IconButton size="small" onClick={handleViewProject}>
        {props.userRole === 'tas' ? <VisibilityIcon color={'nexxusBlack'} /> : <EditIcon color={'nexxusBlack'} />}
      </IconButton>
      {props.userRole === ROLE_SUPERTAS && (
        <>
          <IconButton size="small" disabled={row?.candidateProjectCount > 0 ? false : true} data-cy="DownloadIcon">
            <ReportXls color={row?.candidateProjectCount > 0 ? theme.palette.nexxusBlack.main : '#00000033'} project={row} />
          </IconButton>
          <IconButton
            onClick={handleDuplicateProject}
            size="small"
            sx={{
              margin: '0!important',
            }}
            disabled={[PROJECT_STATUS.DRAFT, PROJECT_STATUS.INTAKE, PROJECT_STATUS.RE_ENABLE].includes(row.status)}
          >
            <ContentCopyIcon
              color={
                [PROJECT_STATUS.DRAFT, PROJECT_STATUS.INTAKE, PROJECT_STATUS.RE_ENABLE].includes(row.status)
                  ? '#ccc'
                  : 'nexxusBlack'
              }
            />
          </IconButton>
        </>
      )}
    </Stack>
  );
};

const setColumns = ({ user }) => {
  return [
    {
      field: 'status',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: ({ row }) => {
        if (row.status === PROJECT_STATUS.ARCHIVED) return <FiberManualRecord color="archived" sx={{ mb: -0.7 }} />;
        if (row.status === PROJECT_STATUS.ACTIVE) return <FiberManualRecord color="active" sx={{ mb: -0.7 }} />;
        if (row.status === PROJECT_STATUS.URGENT) return <FiberManualRecord color="urgent" sx={{ mb: -0.7 }} />;
        if (row.status === PROJECT_STATUS.ON_HOLD) return <FiberManualRecord color="onhold" sx={{ mb: -0.7 }} />;
        if (row.status === PROJECT_STATUS.RE_ENABLE) return <FiberManualRecord sx={{ mb: -0.7, color: amber[500] }} />;
        return <></>;
      },
    },
    {
      field: 'Client.companyName',
      headerName: 'CUSTOMER',
      width: 150,
      sortable: false,
      valueGetter: ({ row }) => row.Client?.companyName || '',
    },
    {
      field: 'position',
      headerName: 'POSITION',
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => Object.values(POSITIONS).find(p => p._id === row.position)?.label || capitalizeString(row.position),
    },
    {
      field: 'technology',
      headerName: 'TECHNOLOGY',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => {
        const skill = row?.Skills.filter((sk) => sk.Project_Skill.skill_type === 3);
        return skill[0]?.name || '';
      },
    },
    {
      field: 'teamsize',
      headerName: 'HEADCOUNT',
      width: 130,
      headerAlign: 'center',
      renderCell: ({ row }) => {
        const color = row.hired_count > 0 ? 'success.main' : 'error.main';
        return (
          <Typography variant="cell" color={color} sx={{ margin: 'auto', marginRight: 'calc(auto + 30px)' }}>
            {row.hired_count ?? 0}/{row.teamsize}
          </Typography>
        );
      },
    },
    {
      field: 'Recruiter.full_name',
      headerName: 'RECRUITER',
      width: 200,
      sortable: false,
      valueGetter: ({ row }) => row.Recruiter?.full_name || '',
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return <RenderActions row={row} userRole={user.role} />;
      },
    },
  ];
};

const Projects = (props) => {
  const theme = useTheme();
  const [pageSize, setPageSize] = React.useState(10);

  return (
    <>
      <Box sx={{ width: '100%', mt: 4 }}>
        <DataGrid
          rows={props?.projects ?? []}
          columns={setColumns(props.auth)}
          autoHeight
          loading={props.isLoading}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          disableSelectionOnClick
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          pagination
          disableColumnMenu
          components={{
            ...theme?.components?.MuiDataGrid?.defaultProps?.components,
            ...{
              NoRowsOverlay: () => <NoDataOverlay />,
              NoResultsOverlay: () => <NoDataOverlay />,
            },
          }}
        />
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
