export const ROLE_CUSTOMER = 'customer';
export const ROLE_ADMIN = 'admin';
export const ROLE_TAS = 'tas';
export const ROLE_SUPERTAS = 'superTas';
export const ROLE_NEXXUS_ADMIN = "nexxusAdmin";
export const ROLE_SUPERCUSTOMER = 'superCustomer';
export const ROLE_ACCOUNT_MANAGER = 'accountManager';
export const ROLE_CANDIDATE = 'candidate';
export const ROLE_HUMAN_RESOURCES = 'humanResources';

export const ROLE_LABELS = {
  customer: 'Customer',
  admin: 'Administrator',
  tas: 'Talent Acquisition',
  superTas: 'Admin Talent Acquisition',
  accountManager: 'Account Manager',
  candidate: 'Candidate',
  superCustomer: 'Admin Customer',
  humanResources: 'Human resources',
  nexxusAdmin: 'Nexxus Administartor'
};

export const PATH_ROLE = (role) => {
  let pathname = role;
  if (pathname === ROLE_SUPERTAS) pathname = 'tas';
  if (pathname === ROLE_SUPERCUSTOMER) pathname = 'customer';
  if (pathname === ROLE_HUMAN_RESOURCES) pathname = 'hr';
  if (pathname === ROLE_NEXXUS_ADMIN) pathname = 'admin';
  return pathname;
};
