const CANDIDATEPROJECT_STATUS = {
  ARCHIVED: 0,
  PANEL_INTERVIEW: 1,
  PANEL_INTERVIEW_REJECTED: 11,
  SKILLS_ASSESSMENT: 2,
  SKILLS_ASSESSMENT_REJECTED: 21,
  BACKGROUND_CHECK: 7,
  SUBMITTED_CUSTOMER: 3,
  SUBMITTED_CUSTOMER_REJECTED: 31,
  CUSTOMER_INTERVIEW: 4,
  CUSTOMER_INTERVIEW_REJECTED: 41,
  OFFER_EXTENDED: 51,
  OFFER_ACCEPTED: 52,
  OFFER_REJECTION: 53,
  HIRED: 5,
  DROPPED_OUT: 6,
};

const CLICKUP_OPTIONS = {
  SKILLS_ASSESSMENT: [
    { _id: 'code assessment', lable: 'Code Assessment' },
    { _id: 'tech interview', label: 'Tech Interview' },
    { _id: 'sonatafy approved', label: 'Sonatafy Approved' },
  ],
  BACKGROUND_CHECK: [
    { _id: 'background check', label: 'Background Check' },
    { _id: 'ready for submission', label: 'Ready for Submission' },
  ],
};

const CANDIDATEPROJECT_STATUS_UPDATED_TEXT = {
  ARCHIVED: 'Process Closed',
  PANEL_INTERVIEW: 'Screening',
  PANEL_INTERVIEW_REJECTED: 'Screening Rejected',
  SKILLS_ASSESSMENT: 'Skills Assesment',
  BACKGROUND_CHECK: 'Background Check',
  SKILLS_ASSESSMENT_REJECTED: 'Sonatafy Rejected',
  SUBMITTED_CUSTOMER: 'Submitted to Customer',
  SUBMITTED_CUSTOMER_REJECTED: 'Submitted to Customer Rejected',
  CUSTOMER_INTERVIEW: 'Client Process',
  CUSTOMER_INTERVIEW_REJECTED: 'Client Rejected',
  OFFER_EXTENDED: 'Offer',
  OFFER_ACCEPTED: 'Offer Accepted',
  OFFER_REJECTION: 'Offer Rejected',
  HIRED: 'Hired',
  DROPPED_OUT: 'Dropped Out',
};

const CANDIDATEPROJECT_COUNTERS = {
  ARCHIVED: 0,
  PANEL_INTERVIEW: 0,
  PANEL_INTERVIEW_REJECTED: 0,
  SKILLS_ASSESSMENT: 0,
  SKILLS_ASSESSMENT_REJECTED: 0,
  SUBMITTED_CUSTOMER: 0,
  SUBMITTED_CUSTOMER_REJECTED: 0,
  CUSTOMER_INTERVIEW: 0,
  CUSTOMER_INTERVIEW_REJECTED: 0,
  OFFER_EXTENDED: 0,
  OFFER_ACCEPTED: 0,
  OFFER_REJECTION: 0,
  HIRED: 0,
  DROPPED_OUT: 0,
};

const GROUP_PROJECT_STATUS_REJECTED = [
  CANDIDATEPROJECT_STATUS.CUSTOMER_INTERVIEW_REJECTED,
  CANDIDATEPROJECT_STATUS.DROPPED_OUT,
  CANDIDATEPROJECT_STATUS.PANEL_INTERVIEW_REJECTED,
  CANDIDATEPROJECT_STATUS.SKILLS_ASSESSMENT_REJECTED,
  CANDIDATEPROJECT_STATUS.SUBMITTED_CUSTOMER_REJECTED,
  CANDIDATEPROJECT_STATUS.OFFER_REJECTION,
];

export {
  CANDIDATEPROJECT_STATUS,
  CANDIDATEPROJECT_COUNTERS,
  GROUP_PROJECT_STATUS_REJECTED,
  CANDIDATEPROJECT_STATUS_UPDATED_TEXT,
  CLICKUP_OPTIONS,
};
