import { Box, Container } from '@mui/material';
import MainLayout from 'components/MainLayoutRedesign/TasMainLayout';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import FormReport from 'pages/tas/reports/form/FormReport';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import reportService from 'services/reportService';
import { loginExpire } from 'store/auth';
import { useGetCustomersQuery } from 'api/api';

const ViewReport = (props) => {
  const maxWidth = props.maxWidth || 'xl';
  const { data: customers } = useGetCustomersQuery();
  const { setBreadcrumbs } = useBreadcrumbs();
  let { ReportId } = useParams();
  const navigate = useNavigate();

  const [report, setReport] = useState({});

  useEffect(() => {
    (async () => {
      const { data } = await reportService.getReport(ReportId);
      if (!data) return navigate('/404');
      setReport(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ReportId, navigate]);

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
        url: '/tas/dashboard',
      },
      {
        name: 'Reports',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="reports">
      <Box>
        <Container maxWidth={maxWidth}>
          <FormReport customers={customers} project={report.Project ?? {}} report={report}></FormReport>
        </Container>
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewReport);
