import PropTypes from 'prop-types';
import { TextField, FormControl, FormLabel, FormHelperText, useTheme } from '@mui/material';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';

const stylesInputDatePicker = makeStyles({
  datepicker: {
    '& > div.Mui-disabled': {
      color: '#979797',
      backgroundColor: '#dadada',
    },
    '& > div.Mui-disabled fieldset': {
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.26)!important',
    },
  },
  focused: {},
  notchedOutline: {},
});

const InputDatePicker = ({ id, label, initialValue, error, onChange, isDisabled = false, placeholder = '', sx }) => {
  const theme = useTheme();
  const [value, setValue] = useState('');
  const classes = stylesInputDatePicker();

  useMemo(() => {
    setValue(initialValue ?? '');
  }, [initialValue]);

  const handleChange = (newValue) => {
    if (newValue) {
      const dateFormatted = moment(moment(newValue.$d)).format('MM-DD-YYYY');
      setValue(dateFormatted);
      onChange(dateFormatted);
    } else {
      setValue('');
      onChange('');
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        className={classes.datepicker}
        value={value !== '' ? moment(value).format('MM/DD/YYYY') : null}
        onChange={handleChange}
        OpenPickerButtonProps={{
          sx: {
            color: theme.palette.primary.main,
            // backgroundColor: '#dadada!important',
          },
        }}
        InputProps={{
          sx: {
            ...sx,
          },
        }}
        renderInput={(params) => {
          return (
            <FormControl fullWidth disabled={isDisabled}>
              {label && label !== '' && (
                <FormLabel error={!!error && error !== ''} htmlFor={id}>
                  {label}
                </FormLabel>
              )}
              <TextField
                {...params}
                error={!!error}
                inputProps={{
                  ...params.inputProps,
                  placeholder,
                }}
                placeholder={placeholder}
              />
              {error && (
                <FormHelperText error id={id}>
                  {error}
                </FormHelperText>
              )}
            </FormControl>
          );
        }}
        disabled={isDisabled}
      />
    </LocalizationProvider>
  );
};

InputDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  initialValue: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default InputDatePicker;
