import MainLayout from 'components/MainLayoutRedesign/TasMainLayout';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import CustomerForm from 'pages/tas/projects/EditProject/CustomerForm';
import ProjectForm from 'pages/tas/projects/EditProject/ProjectForm';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import projectService from 'services/projectService';
import ProjectPipelineCounters from 'components/Projects/ProjectPipelineCounters';
import usePreventLocation from 'hooks/usePreventLocation';
import { hookFormTrimValues } from '../../../../utils/utils';
import { FormHook } from 'hooks/form/Form';
import { CANDIDATEPROJECT_COUNTERS } from 'constants/candidate_project';

const EditProject = (props) => {
  const history = useNavigate();
  const [trySubmit, setTrySubmit] = useState(false);
  const [formClient, setFormClient] = useState(false);
  const [formProject, setFormProject] = useState(false);
  const [dataProject, setDataProject] = useState({});
  const [open, setOpen] = useState(false);
  const { setHasChanged } = usePreventLocation();
  const { ProjectId, ClientId, DuplicatedId } = useParams();
  const { alertFormNotification, setAlertMessage, alertMessage } = FormHook(null, null, false);
  const [candidatesCountStatus, setCandidatesCountStatus] = useState({
    ...CANDIDATEPROJECT_COUNTERS,
  });

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpen(false);
    setFormClient(false);
    setFormProject(false);
    setTrySubmit(false);
  };

  const onAccept = async () => {
    try {
      if (ProjectId) {
        setHasChanged(false);
        const payload = hookFormTrimValues({ ...formClient, ...formProject });
        await projectService.patchProject(payload, ProjectId);
        setOpen(false);
        toast.success('Project saved successfully');
        history(`/tas/projects/${ProjectId}/view`, { replace: true });
      } else {
        setHasChanged(false);
        const payload = hookFormTrimValues({ ...formClient, ...formProject });
        const { data: project } = await projectService.registerProject(payload);
        setOpen(false);
        toast.success('Project saved successfully!');
        history(`/tas/projects/${project.id}/view`);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return toast.error(error.response.data.message);
      }
    }
  };

  //  hook saving forms
  useEffect(() => {
    (async () => {
      if (formClient !== false && formProject !== false) {
        setAlertMessage('dismiss');
        setOpen(true);
      }
    })();
  }, [formClient, formProject]); // eslint-disable-line

  useEffect(() => {
    (async () => {
      const positionId = ProjectId ?? DuplicatedId ?? null;
      if (positionId) {
        const { data } = await projectService.getProject(positionId);
        if (!data) return toast.error('Project not found!');
        const bodyProject = { ...data };
        if (DuplicatedId) {
          bodyProject.status = '';
          const meta = { ...bodyProject.meta };
          meta.parent = bodyProject.id;
          delete bodyProject.id;
          bodyProject.meta = meta;
        }
        setDataProject(bodyProject);
        const { data: countsStatus } = await projectService.getProjectCandidatesCount(ProjectId, {includeBlacklisted: true});
        setCandidatesCountStatus(countsStatus);
      } else if (ClientId) {
        console.log('!!ClientId');
      }
    })();
  }, [ProjectId, DuplicatedId]); // eslint-disable-line

  const { setBreadcrumbs } = useBreadcrumbs();
  const titlePage = useMemo(() => {
    let title = 'CREATE';
    if (ProjectId) title = 'EDIT';
    if (DuplicatedId) title = 'DUPLICATE';
    return title;
  }, [DuplicatedId, ProjectId]);

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
        url: '/tas/dashboard',
      },
      {
        name: 'Projects',
        url: '/tas/projects',
      },
      {
        name: titlePage + ' PROJECT',
      },
    ]);
  }, [DuplicatedId, ProjectId, setBreadcrumbs, titlePage]);

  return (
    <MainLayout {...props} current="projects">
      <ModalFeedback
        variant="info"
        headerText="Project"
        titleText={ProjectId ? 'Do you want to edit this project?' : 'Do you want to add a new project?'}
        open={open}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAccept}
      />
      <Box>
        {/* HEADER FORM*/}
        <Typography variant="h4" align="left" pb={1} sx={{ textTransform: 'uppercase' }}>
          {titlePage} PROJECT
        </Typography>
        <CustomerForm
          project={dataProject}
          validateForm={trySubmit}
          onValidForm={(fieldsCustomer) => {
            if (fieldsCustomer === false) {
              setAlertMessage('invalid');
              setTimeout(() => {
                setTrySubmit(false);
              }, 200);
            } else {
              setFormClient(fieldsCustomer);
            }
          }}
          setStopNavigation={setHasChanged}
        ></CustomerForm>
        {/* CARDS STATUS */}
        {/* <CardStatusForm ProjectId={ProjectId}></CardStatusForm> */}
        <Box sx={{ marginTop: 4, marginBottom: 3 }}>
          <ProjectPipelineCounters candidatesCountStatus={candidatesCountStatus}></ProjectPipelineCounters>
        </Box>
        <Divider sx={{ marginBottom: 1 }} />
        <Box sx={{ mb: 2 }}>{alertFormNotification(alertMessage)}</Box>
        {/* PROJECT FORM */}

        <ProjectForm
          submitForm={trySubmit}
          reset={false}
          project={dataProject}
          onValidForm={(fields) => {
            if (fields === false) {
              setAlertMessage('invalid');
              setTimeout(() => {
                setTrySubmit(false);
              }, 200);
            } else {
              setFormProject(fields);
            }
          }}
          setStopNavigation={setHasChanged}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 6,
          }}
        >
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 'bold',
              textAlign: 'left',
              color: 'nexxusGray.main',
              marginRight: 2,
            }}
          >
            DON&amp;T FORGET TO SAVE YOUR CHANGES
          </Typography>

          <Button
            variant="contained"
            sx={{ minWidth: '180px', height: '30px', borderRadius: 0, paddingX: 1 }}
            color="nexxusBlue"
            onClick={() => (trySubmit ? {} : setTrySubmit(true))}
          >
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 'bold',
                paddingRight: 1,
                color: 'white',
                marginTop: '4px',
              }}
            >
              {trySubmit === true ? (
                <>
                  <CircularProgress style={{ color: 'white', marginRight: 16 }} size="0.8rem" thickness={8} />
                  Validating
                </>
              ) : (
                <Typography
                  component={'span'}
                  sx={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    paddingRight: 1,
                    color: 'white',
                    marginTop: !trySubmit ? '0px' : '4px',
                  }}
                >
                  save project
                </Typography>
              )}
            </Typography>
          </Button>
        </Box>
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProject);
