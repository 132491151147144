import { Divider, Grid, Stack, Button, Typography, Box, styled, Input, IconButton, useTheme, Avatar } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import OnboardingFormFields from './OnboardingFields';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import InputDatePicker from 'components/FormElement/InputDatePicker';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { CLIENTSTATUS, employmentType, timezoneOptsCand } from 'utils/staticData';

import { CANDIDATE_VISA_TYPE_OPTS, CANDIDATE_VISA_STATUS_OPTS } from 'constants/candidate';

import _ from 'lodash';
import { PROJECT_WORKING_HOURS, PROJECT_SENIORITY } from 'constants/project';
import {
  useGetCatalogPhonesCountriesQuery,
  useGetCustomersQuery,
  useGetManagedCountriesQuery,
  useGetSkillsQuery,
} from 'api/api';
import { POSITIONS, UPLOAD_LOCATIONS } from '@nexxus/constants';
import { setNumberFormatted, currencyFormat, stringAvatar } from 'utils/utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useFieldArray } from 'react-hook-form';
import candidateService from 'services/candidateService';
import { CustomButton, InfoPill, InputPhone } from '@nexxus/components';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import projectService from 'services/projectService';
import useSignedS3Url from 'hooks/useSignedS3Url';
import { toast } from 'react-toastify';

const HeaderStack = styled(Stack)({
  gap: '1em',
  '& .MuiTypography-root': {
    color: '#5F6368',
    fontSize: '14.4037px',
    lineHeight: '17px',
    fontWeight: '800',
    '& span': {
      color: '#B3B3B3',
      lineHeight: '16px',
      fontWeight: '700',
    },
  },
});

const FileOnboardComponent = ({ acceptMime, onFileChange, title }) => {
  const theme = useTheme();
  const inputFileRef = useRef();
  const [files, setFiles] = useState([]);
  const resetFileComponent = useCallback(() => {
    // @ts-ignore
    inputFileRef.current.value = null;
    setFiles([]);
    onFileChange([]);
  }, [onFileChange]);

  return (
    <>
      <Input
        inputRef={inputFileRef}
        inputProps={{
          accept: acceptMime.join(','),
        }}
        id={'onboarding_cand_file'}
        type="file"
        disabled={false}
        onChange={(e) => {
          if (e.target.files[0]) {
            if (acceptMime.includes(e.target.files[0].type)) {
              setFiles([e.target.files[0]]);
              onFileChange([e.target.files[0]]);
            }
          }
        }}
        sx={{ display: 'none' }}
      />
      {files?.length > 0 ? (
        <Stack spacing={2} direction="row" alignItems={'center'}>
          <Box sx={{ maxWidth: '220px' }}>{files[0].name}</Box>

          <IconButton
            type="button"
            disabled={false}
            sx={{ p: '10px', '&.Mui-disabled': { opacity: '.5' }, color: '#EF4444', ml: '0!important' }}
            onClick={resetFileComponent}
          >
            <HighlightOffIcon />
          </IconButton>
        </Stack>
      ) : (
        <CustomButton
          id="intake"
          customType="button"
          sx={{
            width: '220px',
            height: '36px',
            fontSize: '16px',
            [theme.breakpoints.down('md')]: { width: '100%' },
            // [theme.breakpoints.up('sm')]: { minWidth: '205px' },
            // [theme.breakpoints.down('sm')]: { margin: '0px!important' },
            // [theme.breakpoints.down('md')]: { ml: '0px!important' },
          }}
          colorfull={false}
          type="button"
          disabled={false}
          onClick={() => {
            inputFileRef?.current?.click();
          }}
          fullWidth={false}
        >
          {title}
        </CustomButton>
      )}
    </>
  );
};

const OnboardingForm = (props) => {
  const theme = useTheme();
  const { candidateId, customerId, projectId, onCancelOnboarding, onSuccesSavedOnboarding, disabled } = props;
  const [candidateInfo, setCandidateInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [fileCv, setFileCv] = useState([]);
  const [onboardingAttachment, setOnboardingAttachment] = useState([]);
  const { data: skills } = useGetSkillsQuery();
  const { data: locationData } = useGetManagedCountriesQuery(
    {
      isHireAvailable: true,
      include: candidateInfo?.candidateCountryId,
    },
    {
      skip: !candidateId,
    }
  );
  const { data: catalogPhoneCountries } = useGetCatalogPhonesCountriesQuery();
  const { data: customers } = useGetCustomersQuery({ excludeStatus: [CLIENTSTATUS.ARCHIVED] });
  const { getSignedDownloadUrl, handleFileUpload } = useSignedS3Url();
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    alertFormNotification,
    control,
  } = OnboardingFormFields();

  const {
    fields: fieldsStakeholders,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'onboarding_customer_stakeholders',
  });

  const handleOnChangeInputSelect = (evt) => {
    if (evt.target.value !== getValues(evt.target.name)) {
      setValue(evt.target.name, evt.target.value);
    }
  };

  const parseRepeater = (event) => {
    const oldValues = [...getValues('onboarding_customer_stakeholders')];
    const { target } = event;
    const parseNewVal = target.name.split('.');
    oldValues[parseNewVal[1]][parseNewVal[2]] = target.value;
    setValue(parseNewVal[0], oldValues);
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      if (fileCv.length) {
        const s3CvFile = await handleFileUpload({
          file: fileCv[0],
          contentType: 'application/pdf',
          path: UPLOAD_LOCATIONS.CANIDATE_CV,
        });
  
        await candidateService.updateCandidateSignedFile(candidateId, 'cv', {
          cv_file_name: fileCv[0].name,
          cv_file_url: s3CvFile?.object_url,
        });
  
        if (s3CvFile) {
          setValue('onboarding_cv_file', s3CvFile.object_url);
          setValue('onboarding_cv_file_originalName', s3CvFile.originalName);
        }
      }
  
      if (onboardingAttachment.length) {
        const s3OnboardingAttachment = await handleFileUpload({
          file: onboardingAttachment[0],
          contentType: onboardingAttachment[0]?.type,
          path: UPLOAD_LOCATIONS.CANDIDATE_ATTACHMENTS,
        });
  
        if (s3OnboardingAttachment) {
          setValue('onboarding_attachment_file', s3OnboardingAttachment.object_url);
          setValue('onboarding_attachment_file_originalName', s3OnboardingAttachment.originalName);
        }
      }
      const { data } = await projectService.saveOnboarding(projectId, { candidateId, onboarding: { ...getValues() } });
      toast.success('Onboarding saved successfully!');
      onSuccesSavedOnboarding(data);
    } catch (error) {
      console.log('error', error)
      toast.error('Something went wrong!, please try again');
      onCancelOnboarding();
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    (async () => {
      if (candidateId && candidateId !== '') {
        const { data } = await candidateService.getCandidateProjectNcs(candidateId, projectId);
        const getDataValue = (dataReference) => dataReference ?? '';
        const getCustomerId = (dataReference) => {
          const getFromOnboard = getDataValue(dataReference);
          return getFromOnboard === '' ? customerId : getFromOnboard;
        };
        reset((prev) => ({
          ...prev,
          onboarding_cand_fullname: getDataValue(data.full_name),
          onboarding_cand_nickname: getDataValue(data?.Project?.Project_Candidate?.onboarding?.onboarding_cand_nickname),
          onboarding_cand_startdate: getDataValue(data?.Project?.Project_Candidate?.onboarding?.onboarding_cand_startdate),
          onboarding_cand_topskills: getDataValue(data?.Project?.Project_Candidate?.onboarding?.onboarding_cand_topskills),
          onboarding_cand_source: getDataValue(data?.Project?.Project_Candidate?.onboarding?.onboarding_cand_source),
          onboarding_cand_salary: getDataValue(data?.Project?.Project_Candidate?.onboarding?.onboarding_cand_salary),
          onboarding_cand_payment_schema: getDataValue(data.employment_type),
          onboarding_cand_additional_notes: getDataValue(
            data?.Project?.Project_Candidate?.onboarding?.onboarding_cand_additional_notes
          ),
          onboarding_cand_email: getDataValue(data.email),
          onboarding_cand_phone: getDataValue(data?.Project?.Project_Candidate?.onboarding?.onboarding_cand_phone),
          onboarding_cand_linkedin: getDataValue(data.linkedin),
          onboarding_cand_visa_status: getDataValue(
            data?.Project?.Project_Candidate?.onboarding?.onboarding_cand_visa_status
          ),
          onboarding_cand_visa_type: getDataValue(data?.Project?.Project_Candidate?.onboarding?.onboarding_cand_visa_type),
          onboarding_cand_timezone: getDataValue(data.timezone),
          onboarding_cand_work_hour_start: getDataValue(
            data?.Project?.Project_Candidate?.onboarding?.onboarding_cand_work_hour_start
          ),
          onboarding_cand_work_hour_end: getDataValue(
            data?.Project?.Project_Candidate?.onboarding?.onboarding_cand_work_hour_end
          ),
          onboarding_cand_location: getDataValue(data.LocationCountryId),
          onboarding_customer_id: getCustomerId(data?.Project?.Project_Candidate?.onboarding?.onboarding_customer_id),
          onboarding_customer_position: getDataValue(
            data?.Project?.Project_Candidate?.onboarding?.onboarding_customer_position
          ),
          onboarding_customer_seniority: getDataValue(data.Project.seniority),
          onboarding_customer_rate: !disabled
            ? getDataValue(data?.Project?.Project_Candidate?.onboarding?.onboarding_customer_rate)
            : currencyFormat.format(getDataValue(data?.Project?.Project_Candidate?.onboarding?.onboarding_customer_rate)),
          onboarding_customer_jd_link: getDataValue(
            data?.Project?.Project_Candidate?.onboarding?.onboarding_customer_jd_link
          ),
          onboarding_customer_webpage: getDataValue(
            data?.Project?.Project_Candidate?.onboarding?.onboarding_customer_webpage
          ),
          onboarding_customer_timezone: getDataValue(
            data?.Project?.Project_Candidate?.onboarding?.onboarding_customer_timezone
          ),
          onboarding_customer_stakeholders: data?.Project?.Project_Candidate?.onboarding
            ?.onboarding_customer_stakeholders ?? [{ name: '', email: '' }],
          onboarding_cv_file: getDataValue(data?.Project?.Project_Candidate?.onboarding?.onboarding_cv_file),
          onboarding_cv_file_originalName: getDataValue(data?.Project?.Project_Candidate?.onboarding?.onboarding_cv_file),
          onboarding_attachment_file: getDataValue(data?.Project?.Project_Candidate?.onboarding?.onboarding_attachment_file),
          onboarding_attachment_file_originalName: getDataValue(
            data?.Project?.Project_Candidate?.onboarding?.onboarding_attachment_file_originalName
          ),
        }));
        setCandidateInfo({
          avatar_url: data.avatar_url,
          full_name: data.full_name,
          developer_title: data.developer_title,
          candidate_code_score: data.candidate_code_score,
          rate_customer: data.rate_customer,
          rate_per_hour: data.rate_per_hour,
          candidateCountryId: data.LocationCountryId,
          candidate_ncs: data?.Project?.Project_Candidate?.meta?.ncs_override ?? data.candidate_ncs,
        });
      }
    })();
  }, [candidateId, reset, customerId, projectId]);

  const strAvatar = stringAvatar(candidateInfo?.full_name);
  if (strAvatar.children && strAvatar.children.length > 3) strAvatar.children = strAvatar.children.slice(0, 3);

  const handleClickDownload = useCallback(
    async (id) => {
      const signedUrl = await getSignedDownloadUrl(getValues(id));
      const anchor = document.createElement('a');
      anchor.href = signedUrl;
      anchor.target = '_blank';
      anchor.click();
      anchor.remove();
    },
    [getSignedDownloadUrl, getValues]
  );

  const options = useMemo(() => {
    return (
      skills
        ?.map((el) => ({ _id: el.id, label: el.name, disabled: el.isActive === false }))
        .filter((sk) => !sk.disabled) ?? []
        // .filter((sk) => !sk.disabled || skDB.includes(sk._id)) ?? []
    );
  }, [skills]);

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <Stack
          spacing={2}
          direction={{ xs: 'column', lg: 'row' }}
          alignItems={'flex-start'}
          sx={{
            width: '100%',
            [theme.breakpoints.down('lg')]: {
              alignItems: 'center',
            },
          }}
        >
          <Stack spacing={2} direction={'row'} alignItems={'flex-start'}>
            {!_.isEmpty(candidateInfo) && (
              <Avatar
                alt={candidateInfo.full_name}
                src={candidateInfo?.avatar_url}
                sx={{ ...strAvatar.sx, width: '100px', height: '100px' }}
              >
                <>{strAvatar.children ?? 'NEX'}</>
              </Avatar>
            )}
            <Box>
              <Typography
                textTransform="uppercase"
                sx={{
                  color: '#656565',
                  fontWeight: '600',
                  fontSize: '19px',
                  lineHeight: '22px',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '8px',
                }}
              >
                {candidateInfo.developer_title}
              </Typography>
              <HeaderStack direction="row" sx={{ marginTop: '4px' }}>
                <Typography sx={{ textTransform: 'uppercase' }}>{candidateInfo.full_name}</Typography>
                <Divider orientation="vertical" flexItem />
                <Typography>
                  CODE TEST: <span>{candidateInfo?.candidate_code_score}</span>
                </Typography>
              </HeaderStack>
              <HeaderStack direction="row" sx={{ marginTop: '8px' }}>
                <Typography>
                  RATE: <span>{currencyFormat.format(candidateInfo?.rate_customer)}</span>
                </Typography>
                <Divider orientation="vertical" flexItem sx={{ margin: 'auto' }} />
                <Typography>
                  <span>{currencyFormat.format(candidateInfo?.rate_per_hour)}/HR</span>
                </Typography>
              </HeaderStack>
            </Box>
          </Stack>
          <Stack
            spacing={2}
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'center', md: 'flex-start' }}
            sx={{
              [theme.breakpoints.up('lg')]: {
                marginLeft: 'auto!important',
              },
              [theme.breakpoints.down('md')]: { width: '100%' },
            }}
          >
            <Stack spacing={0.5}>
              <InfoPill
                text={'NCS'}
                scoreNumber={Number(candidateInfo?.candidate_ncs) ?? 0}
                sx={{ width: '82px', height: '36px', gap: '2px', '& p': { fontSize: '16px' } }}
              ></InfoPill>
            </Stack>
            {!disabled ? (
              <>
                <FileOnboardComponent
                  acceptMime={[
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  ]}
                  onFileChange={(files) => setFileCv(files)}
                  title="UPLOAD RESUME"
                />
                <FileOnboardComponent
                  acceptMime={[
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  ]}
                  onFileChange={(files) => setOnboardingAttachment(files)}
                  title="UPLOAD FILE"
                />
              </>
            ) : (
              <>
                {getValues('onboarding_cv_file') && getValues('onboarding_cv_file') !== '' && (
                  <CustomButton
                    onClick={() => {
                      handleClickDownload('onboarding_cv_file');
                    }}
                    variant="outlined"
                    component="button"
                    sx={{
                      minHeight: '36px',
                      padding: '5px 15px',
                      flex: '1 auto',
                      [theme.breakpoints.down('md')]: {
                        width: '100%',
                      },
                      [theme.breakpoints.up('md')]: {
                        minWidth: '175px',
                      },
                    }}
                  >
                    DOWNLOAD RESUME <FileDownloadOutlinedIcon sx={{ marginLeft: '16px' }} />
                  </CustomButton>
                )}
                {getValues('onboarding_attachment_file') && getValues('onboarding_attachment_file') !== '' && (
                  <CustomButton
                    onClick={() => {
                      handleClickDownload('onboarding_attachment_file');
                    }}
                    variant="outlined"
                    component="button"
                    sx={{
                      minHeight: '36px',
                      padding: '5px 15px',
                      flex: '1 auto',
                      [theme.breakpoints.down('md')]: {
                        width: '100%',
                      },
                      [theme.breakpoints.up('md')]: {
                        minWidth: '175px',
                      },
                    }}
                  >
                    DOWNLOAD FILE <FileDownloadOutlinedIcon sx={{ marginLeft: '16px' }} />
                  </CustomButton>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Divider></Divider>
      </Grid>
      <Grid item xs={12}>
        {alertFormNotification('invalid')}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h7" component="p" sx={{ fontWeight: '700', textAlign: 'left', color: '#5F6368' }}>
          Candidate Information
        </Typography>
      </Grid>
      {/* onboarding_cand_fullname */}
      <Grid item xs={12} sm={6} md={4} xl={4}>
        <InputTextOutlinedV2
          id={'onboarding_cand_fullname'}
          size={'normal'}
          label=""
          placeholder="Full Name"
          error={errors.onboarding_cand_fullname?.message}
          initialValue={getValues('onboarding_cand_fullname') ?? ''}
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
        />
      </Grid>
      {/* onboarding_cand_nickname */}
      {/* onboarding_cand_location */}
      {/* onboarding_cand_startdate */}
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputTextOutlinedV2
          id={'onboarding_cand_nickname'}
          size={'normal'}
          label=""
          placeholder="Preferred Name"
          error={errors.onboarding_cand_nickname?.message}
          initialValue={getValues('onboarding_cand_nickname') ?? ''}
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputAutoCompleteV2
          id="onboarding_cand_location"
          size={'normal'}
          autocomplete
          label=""
          placeholder="Location"
          initialValue={getValues('onboarding_cand_location')}
          error={errors.onboarding_cand_location?.message}
          opts={
            locationData?.map((location) => ({
              _id: location.id,
              label: location.name,
            })) ?? []
          }
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputDatePicker
          id={'onboarding_cand_startdate'}
          label=""
          placeholder="Start date"
          onChange={(value) => setValue('onboarding_cand_startdate', value)}
          initialValue={getValues('onboarding_cand_startdate')}
          error={errors.onboarding_cand_startdate?.message}
          isDisabled={disabled}
        />
      </Grid>
      {/* onboarding_cand_topskills */}
      <Grid item xs={12} sm={6} md={4} xl={4}>
        <InputAutoCompleteV2
          id={'onboarding_cand_topskills'}
          size={'normal'}
          label=""
          placeholder="Top Skills"
          error={errors.onboarding_cand_topskills?.message}
          initialValue={getValues('onboarding_cand_topskills')}
          opts={options ?? []}
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
          multiple={true}
          typeRenderOptions={'tags'}
        />
      </Grid>
      {/* onboarding_cand_source */}
      {/* onboarding_cand_salary */}
      {/* onboarding_cand_payment_schema */}
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputAutoCompleteV2
          id={'onboarding_cand_source'}
          size={'normal'}
          label=""
          placeholder="Candidate Source"
          error={errors.onboarding_cand_source?.message}
          initialValue={getValues('onboarding_cand_source')}
          opts={[
            { _id: 1, label: 'External Referral' },
            { _id: 2, label: 'Ex Employee' },
            { _id: 3, label: 'Internal Referral' },
            { _id: 4, label: 'LinkedIn' },
            { _id: 5, label: 'Sourcing' },
            { _id: 6, label: 'Ulinc' },
            { _id: 7, label: '3rd Party' },
          ]}
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputTextOutlinedV2
          id={'onboarding_cand_salary'}
          size={'normal'}
          label=""
          placeholder="Salary"
          error={errors.onboarding_cand_salary?.message}
          initialValue={getValues('onboarding_cand_salary') ?? ''}
          onChangeInput={handleOnChangeInputSelect}
          onlyNumbers={true}
          type={'number'}
          isDisabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputAutoCompleteV2
          id={'onboarding_cand_payment_schema'}
          size={'normal'}
          label=""
          placeholder="Payment Scheme"
          error={errors.onboarding_cand_payment_schema?.message}
          initialValue={getValues('onboarding_cand_payment_schema')}
          opts={employmentType}
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
        />
      </Grid>
      {/* onboarding_cand_additional_notes */}
      <Grid item xs={12} sm={6} md={4} xl={4}>
        <InputTextOutlinedV2
          id={'onboarding_cand_additional_notes'}
          size={'normal'}
          label=""
          placeholder="Additional Notes (i.e. Future time off, red flags, etc.)"
          error={errors.onboarding_cand_additional_notes?.message}
          initialValue={getValues('onboarding_cand_additional_notes')}
          onChangeInput={handleOnChangeInputSelect}
          styleLabel={{ marginTop: '0!important' }}
          isDisabled={disabled}
          styleInput={{ mr: 1 }}
        />
      </Grid>
      {/* onboarding_cand_email*/}
      {/*onboarding_cand_phone*/}
      {/*onboarding_cand_linkedin */}
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputTextOutlinedV2
          id={'onboarding_cand_email'}
          size={'normal'}
          label=""
          placeholder="Personal Email"
          error={errors.onboarding_cand_email?.message}
          initialValue={getValues('onboarding_cand_email') ?? ''}
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputPhone
          id="onboarding_cand_phone"
          defaultCountry={'mx'}
          initialValue={getValues('onboarding_cand_phone') ?? ''}
          error={errors?.onboarding_cand_phone?.message}
          isDisabled={disabled}
          arrOpts={catalogPhoneCountries?.map((pc) => pc.short_name) || ['us', 'mx']}
          handleOnChange={(val) => handleOnChangeInputSelect({ target: { name: 'onboarding_cand_phone', value: val } })}
          label=""
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputTextOutlinedV2
          id={'onboarding_cand_linkedin'}
          size={'normal'}
          label=""
          placeholder="LinkedIn Profile"
          error={errors.onboarding_cand_linkedin?.message}
          initialValue={getValues('onboarding_cand_linkedin') ?? ''}
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
        />
      </Grid>
      {/* onboarding_cand_visa_status */}
      {/* onboarding_cand_visa_type */}
      <Grid item xs={12} sm={6} md={4} xl={2}>
        <InputAutoCompleteV2
          id={'onboarding_cand_visa_status'}
          size={'normal'}
          label=""
          placeholder="Visa Status"
          error={errors.onboarding_cand_visa_status?.message}
          initialValue={getValues('onboarding_cand_visa_status')}
          opts={CANDIDATE_VISA_STATUS_OPTS}
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={2}>
        <InputAutoCompleteV2
          id={'onboarding_cand_visa_type'}
          size={'normal'}
          label=""
          placeholder="Visa Type"
          error={errors.onboarding_cand_visa_type?.message}
          initialValue={getValues('onboarding_cand_visa_type')}
          opts={CANDIDATE_VISA_TYPE_OPTS}
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
        />
      </Grid>
      {/* onboarding_cand_timezone */}
      {/* onboarding_cand_work_hour_start */}
      {/* onboarding_cand_work_hour_end */}
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputAutoCompleteV2
          id="onboarding_cand_timezone"
          size={'normal'}
          label=""
          placeholder="Timezone"
          initialValue={getValues('onboarding_cand_timezone')}
          error={errors.onboarding_cand_timezone?.message}
          opts={_.orderBy(timezoneOptsCand, [(timezone) => timezone._id.toLowerCase()], ['asc'])}
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputAutoCompleteV2
          id={`onboarding_cand_work_hour_start`}
          opts={PROJECT_WORKING_HOURS()}
          size={'normal'}
          initialValue={getValues('onboarding_cand_work_hour_start')}
          error={errors.onboarding_cand_work_hour_start?.message}
          label=""
          placeholder="Working Hours (Start)"
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
          sortOpts={false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputAutoCompleteV2
          id={`onboarding_cand_work_hour_end`}
          opts={PROJECT_WORKING_HOURS()}
          size={'normal'}
          initialValue={getValues('onboarding_cand_work_hour_end')}
          error={errors.onboarding_cand_work_hour_end?.message}
          label=""
          placeholder="Working Hours (End)"
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
          sortOpts={false}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider></Divider>
      </Grid>
      <Grid item xs={12}>
        <Typography varian="h7" component="p" sx={{ fontWeight: '700', textAlign: 'left', color: '#5F6368' }}>
          Customer Information
        </Typography>
      </Grid>
      {/* onboarding_customer_id */}
      <Grid item xs={12} sm={6} md={4} xl={4}>
        <InputAutoCompleteV2
          id={'onboarding_customer_id'}
          size={'normal'}
          label=""
          placeholder="Customer"
          error={errors.onboarding_customer_id?.message}
          initialValue={getValues('onboarding_customer_id')}
          opts={customers?.map((client) => ({ _id: client.id, label: client.companyName })) || []}
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={true}
          sortOpts={false}
        />
      </Grid>
      {/* onboarding_customer_position */}
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputAutoCompleteV2
          id={'onboarding_customer_position'}
          size={'normal'}
          placeholder="Position"
          error={errors.onboarding_customer_position?.message}
          initialValue={getValues('onboarding_customer_position')}
          opts={Object.values(POSITIONS)}
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
        />
      </Grid>
      {/* onboarding_customer_seniority */}
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputAutoCompleteV2
          id={'onboarding_customer_seniority'}
          size={'normal'}
          placeholder="Seniority"
          error={errors.onboarding_customer_seniority?.message}
          initialValue={getValues('onboarding_customer_seniority')}
          opts={Object.values(PROJECT_SENIORITY)}
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
        />
      </Grid>
      {/* onboarding_customer_rate */}
      <Grid item xs={12} sm={6} md={4} xl={2.66}>
        <InputTextOutlinedV2
          id={'onboarding_customer_rate'}
          size={'normal'}
          type={disabled ? 'text' : 'number'}
          placeholder={`Rate`}
          error={errors.onboarding_customer_rate?.message}
          initialValue={setNumberFormatted(getValues('onboarding_customer_rate'), false)}
          onChangeInput={handleOnChangeInputSelect}
          isDisabled={disabled}
          onlyNumbers={true}
        />
      </Grid>

      <Grid item xs={12} xl={4}>
        <Grid container spacing={2}>
          {/* onboarding_customer_jd_link */}
          {/* <Grid item xs={12} sm={6} md={4} xl={4}> */}
          <Grid item xs={12} sm={6} md={4} lg={4} xl={12}>
            <InputTextOutlinedV2
              id={'onboarding_customer_jd_link'}
              size={'normal'}
              label=""
              placeholder="Job Description (URL)"
              error={errors.onboarding_customer_jd_link?.message}
              initialValue={getValues('onboarding_customer_jd_link') ?? ''}
              onChangeInput={handleOnChangeInputSelect}
              isDisabled={disabled}
            />
          </Grid>
          {/* onboarding_customer_webpage */}
          <Grid item xs={12} sm={6} md={4} lg={4} xl={12}>
            <InputTextOutlinedV2
              id={'onboarding_customer_webpage'}
              size={'normal'}
              label=""
              placeholder="Cliente Web Page (URL)"
              error={errors.onboarding_customer_webpage?.message}
              initialValue={getValues('onboarding_customer_webpage') ?? ''}
              onChangeInput={handleOnChangeInputSelect}
              isDisabled={disabled}
            />
          </Grid>
          {/* onboarding_customer_timezone */}
          <Grid item xs={12} sm={6} md={4} lg={4} xl={12}>
            <InputAutoCompleteV2
              id="onboarding_customer_timezone"
              size={'normal'}
              label=""
              placeholder="Timezone"
              initialValue={getValues('onboarding_customer_timezone')}
              error={errors.onboarding_customer_timezone?.message}
              opts={_.orderBy(timezoneOptsCand, [(timezone) => timezone._id.toLowerCase()], ['asc'])}
              onChangeInput={handleOnChangeInputSelect}
              isDisabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* onboarding_customer_stakeholders */}
      <Grid item xs={12} xl={8}>
        <Grid container spacing={2} alignItems={'flex-start'}>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              '& div:last-child': {
                pb: '0!important',
              },
            }}
          >
            {fieldsStakeholders?.map(({ name, email }, index) => (
              <Grid
                container
                key={`onboarding_customer_stakeholders.${index.toString()}`}
                spacing={2}
                alignItems={'flex-start'}
                sx={{ pb: 2 }}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <InputTextOutlinedV2
                    id={`onboarding_customer_stakeholders.${index}.name`}
                    size={'normal'}
                    label=""
                    placeholder="Client’s Name (POC)"
                    error={errors?.onboarding_customer_stakeholders?.[index]?.name?.message}
                    initialValue={name}
                    onChangeInput={parseRepeater}
                    isDisabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <InputTextOutlinedV2
                    id={`onboarding_customer_stakeholders.${index}.email`}
                    size={'normal'}
                    label=""
                    placeholder="Client’s Email (POC)"
                    error={errors?.onboarding_customer_stakeholders?.[index]?.email?.message}
                    initialValue={email}
                    onChangeInput={parseRepeater}
                    isDisabled={disabled}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
              <Stack
                direction={{ xs: 'row', lg: 'column', xl: 'row' }}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                spacing={1}
              >
                <Button
                  variant="outlined"
                  sx={{ border: 'none!important', p: 0, '&:hover': { backgroundColor: '#fff' } }}
                  onClick={() => append({ name: '', email: '' })}
                  disabled={!(!disabled && getValues('onboarding_customer_stakeholders')?.length !== 3)}
                >
                  <AddCircleIcon sx={{ mr: 1 }} /> ADD NEW
                </Button>

                <Button
                  variant="outlined"
                  sx={{ border: 'none!important', p: 0, '&:hover': { backgroundColor: '#fff' } }}
                  onClick={() => remove(1)}
                  //true hace disabled
                  disabled={disabled ?? getValues('onboarding_customer_stakeholders')?.length === 1}
                >
                  <RemoveCircleIcon sx={{ mr: 1 }} />
                  REMOVE
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {!disabled ? (
          <Stack spacing={2} direction={'row'} justifyContent={'flex-end'} sx={{ mb: 4 }}>
            <Button
              type="button"
              fullWidth
              variant="outlined"
              sx={{ flex: '1 auto', maxWidth: '300px' }}
              onClick={onCancelOnboarding}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              fullWidth
              disabled={isLoading}
              variant="contained"
              sx={{ flex: '1 auto', maxWidth: '300px', backgroundColor: '#1841A3' }}
            >
              Save
            </Button>
          </Stack>
        ) : (
          <Stack spacing={2} direction={'row'} sx={{ py: 10 }} justifyContent={'flex-end'} alignItems={'center'}>
            <CustomButton
              onClick={() => {}}
              variant="outlined"
              component="button"
              sx={{
                maxWidth: '200px',
                minHeight: '36px',
                padding: '5px 15px',
                flex: '1 auto',
              }}
              disabled
            >
              DOWNLOAD PDF <FileDownloadOutlinedIcon sx={{ marginLeft: '16px' }} />
            </CustomButton>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default OnboardingForm;
