import { FiberManualRecord } from '@mui/icons-material';
import { IconButton, Stack, TableCell as MuiTableCell, TableRow, Typography, useTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { ROLE_SUPERTAS } from 'constants/users';
import { connect } from 'react-redux';
import { PROJECT_STATUS } from '../../../utils/staticData';
import ProjectPopover from './ProjectPopover';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReportXls from '../dashboard/ReportXls';
import { POSITIONS } from '@nexxus/constants';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

const RenderActiveProjects = ({ projects = [], activeProjectCount, auth: { user } }) => {
  const theme = useTheme();
  if (activeProjectCount < 1) {
    return (
      <>
        <TableRow>
          <TableCell rowSpan={10} colSpan={9} align="center">
            <strong style={{ color: 'gray' }}>NO DATA</strong>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
      </>
    );
  }

  return projects.map((project, index) => {
    const getColorFromStatus = (projStatus) => {
      let colorStatus;
      switch (projStatus) {
        case PROJECT_STATUS.ARCHIVED:
          colorStatus = 'archived';
          break;
        case PROJECT_STATUS.ACTIVE:
          colorStatus = 'active';
          break;
        case PROJECT_STATUS.URGENT:
          colorStatus = 'urgent';
          break;
        default:
          colorStatus = 'onhold';
      }
      return colorStatus;
    };
    return (
      <TableRow key={`active-project-${index}`}>
        <TableCell align="left">
          <FiberManualRecord color={getColorFromStatus(project.status)} sx={{ mb: -0.7 }} />
        </TableCell>
        <TableCell align="left">
          <Typography sx={{ fontWeight: 'bold', fontSize: '12px', textTransform: 'uppercase' }}>
            {Object.values(POSITIONS).find(p => p._id === project.position)?.label || project.position}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography sx={{ fontWeight: 'bold', fontSize: '12px', textTransform: 'uppercase' }}>
            {project.Skills.find((sk) => sk.Project_Skill.skill_type === 3)?.name || ''}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography sx={{ fontWeight: 'bold', fontSize: '12px', textTransform: 'uppercase' }}>
            {project.seniority}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <ProjectPopover project={project}></ProjectPopover>
        </TableCell>
        <TableCell align="center">
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '12px',
              textTransform: 'uppercase',
              color: parseInt(project.hired_count) === 0 ? '#FF6347' : '#00FF7F',
            }}
          >
            {`${project.hired_count}/${project.teamsize}`}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography sx={{ fontWeight: 'bold', fontSize: '12px', textTransform: 'uppercase' }}>
            {`${project.Recruiter?.full_name || ''}`}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start' }}>
            <IconButton component={Link} to={`/tas/projects/${project.id}/view`}>
              <VisibilityIcon sx={{ fontSize: 20, color: theme.palette.nexxusBlack.main }} />
            </IconButton>
            {[ROLE_SUPERTAS].includes(user.role) && (
              <IconButton size="small" disabled={project?.Candidates.length > 0 ? false : true} data-cy="DownloadIcon">
                <ReportXls
                  color={project?.Candidates.length > 0 ? theme.palette.nexxusBlack.main : '#00000033'}
                  project={project}
                />
              </IconButton>
            )}
          </Stack>
        </TableCell>
      </TableRow>
    );
  });
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderActiveProjects);
