import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, Stack, Typography } from '@mui/material';

import { FiberManualRecord } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ROLE_SUPERTAS } from 'constants/users';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';
import { amber, deepOrange } from '@mui/material/colors';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { capitalizeString } from 'utils/utils';
import { PROJECT_STATUS } from 'constants/project';
import { POSITIONS } from '@nexxus/constants';

const NoDataOverlay = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
        NO DATA
      </Typography>
      <Typography variant="h6" color="nexxusGrayLight.main">
        Please Select Candidate Fields
      </Typography>
    </Stack>
  );
};

const RenderActions = (props) => {
  const { row } = props;

  const navigate = useNavigate();

  const handleViewProject = (ev) => {
    ev.preventDefault();
    navigate(`/tas/projects/${row.id}/view`);
  };

  const handleEditProject = (ev) => {
    ev.preventDefault();
    navigate(`/tas/projects/${row.id}/edit`);
  };

  const handleDuplicateProject = (ev) => {
    ev.preventDefault();
    navigate(`/tas/projects/create/based_project/${row.id}`);
  };

  return (
    <Stack direction="row" spacing={1} justifyContent={'flex-start'} sx={{ minWidth: '80px' }}>
      <IconButton onClick={handleViewProject} size="small">
        <VisibilityIcon color={'nexxusBlack'} />
      </IconButton>
      {props.userRole === ROLE_SUPERTAS && (
        <>
          <IconButton onClick={handleEditProject} size="small" sx={{ margin: '0!important' }}>
            <EditIcon color={'nexxusBlack'} />
          </IconButton>
          <IconButton
            onClick={handleDuplicateProject}
            size="small"
            sx={{
              margin: '0!important',
            }}
            disabled={[PROJECT_STATUS.DRAFT, PROJECT_STATUS.INTAKE, PROJECT_STATUS.RE_ENABLE].includes(row.status)}
          >
            <ContentCopyIcon
              color={
                [PROJECT_STATUS.DRAFT, PROJECT_STATUS.INTAKE, PROJECT_STATUS.RE_ENABLE].includes(row.status)
                  ? '#ccc'
                  : 'nexxusBlack'
              }
            />
          </IconButton>
        </>
      )}
    </Stack>
  );
};

const ProjectList = (props) => {
  const theme = useTheme();
  const [pageSize, setPageSize] = React.useState(10);
  const columns = [
    {
      field: 'status',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: ({ row }) => {
        if (row.status === PROJECT_STATUS.ARCHIVED) return <FiberManualRecord color="archived" sx={{ mb: -0.7 }} />;
        if (row.status === PROJECT_STATUS.ACTIVE) return <FiberManualRecord color="active" sx={{ mb: -0.7 }} />;
        if (row.status === PROJECT_STATUS.URGENT) return <FiberManualRecord color="urgent" sx={{ mb: -0.7 }} />;
        if (row.status === PROJECT_STATUS.ON_HOLD) return <FiberManualRecord color="onhold" sx={{ mb: -0.7 }} />;
        if (row.status === PROJECT_STATUS.RE_ENABLE) return <FiberManualRecord sx={{ mb: -0.7, color: amber[500] }} />;
        if (row.status === PROJECT_STATUS.INTAKE) return <FiberManualRecord sx={{ mb: -0.7, color: deepOrange[300] }} />;
        return <></>;
      },
    },
    {
      field: 'position',
      headerName: 'POSITION',
      flex: 1,
      valueGetter: ({ row }) => Object.values(POSITIONS).find(p => p._id === row.position)?.label || capitalizeString(row.position),
    },
    {
      field: 'customer',
      headerName: 'CUSTOMER',
      flex: 1,
      valueGetter: ({ row }) => capitalizeString(row.Client.companyName),
    },
    {
      field: 'seniority',
      headerName: 'SENIORITY',
      flex: 0.5,
      valueGetter: ({ row }) => row.seniority,
    },
    {
      field: 'technology',
      headerName: 'TECHNOLOGY',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      valueGetter: ({ row }) => {
        const skill = row?.Skills.filter((sk) => sk.Project_Skill.skill_type === 3);
        return skill[0]?.name || '';
      },
    },
    {
      field: 'teamsize',
      headerName: 'HEADCOUNT',
      width: 130,
      headerAlign: 'center',
      renderCell: ({ row }) => {
        const color = row.hired_count > 0 ? 'success.main' : 'error.main';
        return (
          <Typography variant="cell" color={color} sx={{ margin: 'auto', marginRight: 'calc(auto + 30px)' }}>
            {row.hired_count ?? 0}/{row.teamsize}
          </Typography>
        );
      },
    },
    // {
    //   field: 'skills',
    //   headerName: 'SKILLS',
    //   width: 100,
    //   sortable: false,
    //   align: 'center',
    //   headerAlign: 'center',
    //   cellClassName: 'skill--cell',
    //   renderCell: (params) => {
    //     return <SkillsPopover label="VIEW ALL" skills={params.row.Skills} />;
    //   },
    // },
    {
      field: 'datecreated',
      headerName: 'DATE CREATED',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      valueGetter: ({ row }) => moment(row.createdAt).format('MMM DD, YYYY'),
    },
    {
      field: 'Recruiter.full_name',
      headerName: 'RECRUITER',
      flex: 1,
      valueGetter: ({ row }) => row.Recruiter?.full_name || '',
    },
    {
      field: 'view',
      headerName: 'ACTIONS',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return <RenderActions row={row} userRole={props.auth.user.role} />;
      },
    },
  ];

  return (
    <>
      <Stack direction="row" justifyContent={'flex-start'} alignItems="center" spacing={2} sx={{ width: '100%', mt: 4 }}>
        <Typography sx={{ fontSize: 20, whiteSpace: 'nowrap' }}>Position status</Typography>
        {props.type.includes('active') && (
          <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
            <FiberManualRecord color="active" sx={{ mb: -0.7 }} /> ACTIVE
          </Typography>
        )}
        {props.type.includes('urgent') && (
          <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
            <FiberManualRecord color="urgent" sx={{ mb: -0.7 }} /> URGENT
          </Typography>
        )}
        {props.type.includes('re_enable') && (
          <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
            <FiberManualRecord sx={{ mb: -0.7, color: amber[500] }} /> RE ENABLE
          </Typography>
        )}
        {props.type.includes('intake') && (
          <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
            <FiberManualRecord sx={{ mb: -0.7, color: deepOrange[300] }} /> INTAKE
          </Typography>
        )}
        {props.type.includes('onhold') && (
          <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
            <FiberManualRecord color="onhold" sx={{ mb: -0.7 }} /> ON HOLD
          </Typography>
        )}
        {props.type.includes('archived') && (
          <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
            <FiberManualRecord color="archived" sx={{ mb: -0.7 }} /> ARCHIVED
          </Typography>
        )}
      </Stack>
      <Box
        sx={{
          minHeight: '360px',
          width: '100%',
          mt: 1,
          '& .skill--cell': {
            justifyContent: 'center !important',
          },
        }}
      >
        <DataGrid
          rows={props.projects || []}
          columns={columns}
          autoHeight
          loading={props.isLoading}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          disableSelectionOnClick
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          pagination
          disableColumnMenu
          components={{
            ...theme.components?.MuiDataGrid?.defaultProps?.components,
            ...{
              NoRowsOverlay: () => <NoDataOverlay />,
              NoResultsOverlay: () => <NoDataOverlay />,
            },
          }}
          initialState={
            props.initialOrder
              ? {
                  sorting: {
                    sortModel: [{ field: props.initialOrder, sort: 'desc' }],
                  },
                }
              : {}
          }
        />
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
