import CircularProgress from '@mui/material/CircularProgress';
import links from '../../decorators/links';

// More on default export: https://storybook.js.org/docs/react/writing-stories/introduction#default-export
export default {
  title: 'Playground/MUI',
  // More on argTypes: https://storybook.js.org/docs/react/api/argtypes
  parameters: { options: { showPanel: true } },
  argTypes: {
    variant: { control: 'select', options: ['determinate', 'indeterminate'] },
    color: { control: 'select', options: ['primary', 'secondary', 'success', 'info', 'warning', 'error'] },
    value: { control: { type: 'range', min: 0, max: 100, step: 1 } },
  },
  decorators: [
    (Story) => {
      return links(
        Story,
        'https://mui.com/material-ui/react-progress/',
        'https://mui.com/material-ui/api/circular-progress/'
      );
    },
  ],
};

export const CircularProgressMUI = (args) => <CircularProgress {...args} />;

CircularProgressMUI.storyName = 'CircularProgress';
// More on args: https://storybook.js.org/docs/react/writing-stories/args
CircularProgressMUI.args = {
  color: 'primary',
  variant: 'indeterminate',
  size: 40,
  thickness: 3.6,
  disableShrink: false,
  value: 30,
};
