import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';

const Breadcrumbs = (props) => {
  const { breadcrumbs } = useBreadcrumbs();
  return (
    <MuiBreadcrumbs aria-label="breadcrumb" {...props}>
      {breadcrumbs.map((breadcrumb, index) =>
        breadcrumb.url ? (
          <Link key={breadcrumb.url} href={breadcrumb.url}>
            {breadcrumb.name}
          </Link>
        ) : (
          <Typography sx={{ color: 'rgb(95, 161, 255)' }} key={`${breadcrumb.name}_${index}`}>{breadcrumb.name}</Typography>
        )
      )}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
