import { CANDIDATEPROJECT_STATUS } from 'constants/candidate_project';
import http from './httpService';

function getAllSkills() {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/skills/all`);
}

function getAllProjects(body) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/projects/`, {
    params: body,
  });
}

function getAllCounters() {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/projects/counters`);
}

function getAllRecruiters() {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/projects/recruiters`);
}

function getProjectsByClient(ClientId, filters) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/projects/client/${ClientId}`, {
    params: filters,
  });
}

function getActiveProjectsByCandidate(CandidateId) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/projects/candidate/${CandidateId}`, {
    status: [
      CANDIDATEPROJECT_STATUS.SUBMITTED_CUSTOMER,
      CANDIDATEPROJECT_STATUS.PANEL_INTERVIEW,
      CANDIDATEPROJECT_STATUS.CUSTOMER_INTERVIEW,
    ],
  });
}

function getProject(ProjectId) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/projects/${ProjectId}`);
}

function getProjectCandidates(ProjectId) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/projects/${ProjectId}/candidates`);
}

function getProjectCandidatesCount(ProjectId, params) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/projects/${ProjectId}/candidates/counts`, { params });
}

/**
 * @param {number} candidateId
 * @param {number} projectId
 * @param {string} status
 * @param {string} clickupSatatus
 */
function candidateEnrollment(candidateId, projectId, status, clickupSatatus = '') {
  return http.post(`${process.env.REACT_APP_API_BACKEND}/v1/projects/${projectId}/enrollment`, {
    CandidateId: candidateId,
    status: status,
    clickupSatatus,
  });
}

function isCandidateEnrolled(candidateId, clientId, projectId) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/projects/${projectId}/isenrolled`, {
    params: {
      ClientId: clientId,
      CandidateId: candidateId,
    },
  });
}

function registerProject(body) {
  return http.post(process.env.REACT_APP_API_BACKEND + '/v1/projects', body);
}

function patchProject(body, ProjectId) {
  return http.patch(`${process.env.REACT_APP_API_BACKEND}/v1/projects/${ProjectId}`, body);
}

function getCandidateAllProjectsByStatus(CandidateId, body) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/candidates/${CandidateId}/projects`, {
    params: {
      status: body,
    },
  });
}

function uploadProjectAttachments(formData, key) {
  return http.post(process.env.REACT_APP_API_BACKEND + '/v1/projects/attachments', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function saveOnboarding(ProjectId, body) {
  return http.post(process.env.REACT_APP_API_BACKEND + `/v1/projects/${ProjectId}/onboarding`, body);
}

function getOnboarding(ProjectId, body) {
  return http.get(process.env.REACT_APP_API_BACKEND + `/v1/projects/${ProjectId}/onboarding`, {
    params: {
      candidateId: body.candidateId,
    },
  });
}

export default {
  getAllProjects,
  getAllCounters,
  getProject,
  getProjectCandidates,
  getProjectsByClient,
  getProjectCandidatesCount,
  registerProject,
  uploadProjectAttachments,
  patchProject,
  candidateEnrollment,
  isCandidateEnrolled,
  getAllSkills,
  getAllRecruiters,
  getCandidateAllProjectsByStatus,
  getActiveProjectsByCandidate,
  saveOnboarding,
  getOnboarding,
};
