import TagsDataGrid from './TagsDataGrid';
import { useTags } from 'hooks/useTagsRefactored';
import CreateTagForm from './CreateTagForm';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';

const TagsPageContent = () => {
  const { tags, refetch } = useTags();

  const onSuccess = (data) => {
    console.log(data);
    refetch();
    toast.success('Tag created');
  };

  const onError = (error) => {
    console.log(error);
    toast.error('Error creating tag');
  };

  const onDelete = () => {
    refetch();
  };

  return (
    <Box padding={5}>
      <CreateTagForm onSuccess={onSuccess} onError={onError} />
      <TagsDataGrid onDelete={onDelete} tags={tags} />
    </Box>
  );
};

export default TagsPageContent;
