import { Grid, useMediaQuery } from '@mui/material';
import { CustomSelect } from '@nexxus/components';
import NCSWidget from './NCSWidget';

const CandidateHeader = ({
  logoUrl,
  positions,
  onHandlePosition,
  lifetimeTotals,
  projectSelected,
}) => {
  const isMobile = useMediaQuery('(max-width:700px)');
  const isNcsBreakpoint = useMediaQuery('(min-width:985px)');

  return (
    <Grid
      container
      justifyContent={'space-between'}
      sx={isNcsBreakpoint ? { padding: '16px', background: 'white', borderRadius: '5px' } : {}}
    >
      {!isMobile && (
        <Grid item md={isNcsBreakpoint ? 9 : 12} sx={!isNcsBreakpoint ? { width: '100%' } : {}}>
          <Grid
            container
            spacing={isNcsBreakpoint ? 2 : 0}
            sx={!isNcsBreakpoint ? { background: 'white', borderRadius: '5px', padding: '16px' } : {}}
          >
            <Grid item sm={5}>
              <CustomSelect
                options={positions ?? []}
                onChangeInput={onHandlePosition}
                selectText="Select a position"
                variant={'variant-1'}
                defaultOption={false}
                MenuProps={{
                  PaperProps: {
                    sx: { minWidth: '270px' },
                  },
                }}
                width="100%"
                initialValue={projectSelected}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      {!isMobile && isNcsBreakpoint && <NCSWidget lifetimeTotals={lifetimeTotals} logoUrl={logoUrl} />}
    </Grid>
  );
};

export default CandidateHeader;
