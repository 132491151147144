import React, { useState, useEffect } from 'react';
import MainLayout from 'components/MainLayoutRedesign/TasMainLayout';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';

import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import ManageCountriesContent from '../ManageCountries/ManageCountriesContent';
import { TabPanel } from 'utils/tabPanels';
import TagsPageContent from 'pages/tas/tags/TagsPageContent';
import AdminSkills from 'pages/tas/adminSkills/AdminSkills';
import useBreadcrumbs from 'hooks/useBreadcrumbs';

const CandidateSettings = (props) => {
  const [value, setValue] = useState(0);

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Candidate Settings',
      },
    ]);
  }, [setBreadcrumbs]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <MainLayout variant="transition" {...props} current="administration">
      <Box pt={2} pb={2} p={3}>
        <Typography
          variant="h4"
          mb={3}
          sx={{
            color: '#004DD3',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
          }}
        >
          Candidates Settings
        </Typography>
        <Paper>
          <Tabs value={value} onChange={handleChange} centered sx={{ width: '100%' }}>
            <Tab sx={{ width: '33%' }} label="Manage Countries" />
            <Tab sx={{ width: '33%' }} label="Manage Tags" />
            <Tab sx={{ width: '33%' }} label="Manage Skills" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <ManageCountriesContent />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TagsPageContent />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AdminSkills />
          </TabPanel>
        </Paper>
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSettings);
