import { connect } from 'react-redux';
import { Navigate, Route, Routes, unstable_HistoryRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import Page403 from '../pages/403';
import Page404 from '../pages/404';
import Sandbox from '../sandbox/Sandbox';
import { loginExpire, logout } from '../store/auth';
import { AccountManagagerRoutes } from './accountManager';
import AuthRoutes from './authRouter';
import { CandidateRoutes } from './candidateRouter';
import { CustomerRoutes } from './customerRouter';
import { TasRoutes } from './tasRouter';
import { AdminRoutes } from 'routers/adminRouter';
import { MeRoutes } from 'routers/meRouter';
import { ImportRoutes } from 'routers/importRouter';
import { AuditTrailRoutes } from 'routers/auditTrailRouter';
import { HumanResourcesRoutes } from 'routers/humanResourcesRouter';
import PrivateRoute from 'routers/privateRouter';
import ScrollToTop from 'utils/ScrollToTop';
import history from 'services/history';

import { ErrorBoundary } from 'react-error-boundary';
import { Box, Grid } from '@mui/material';
import MainLayout from 'components/MainLayout/MainLayout';
import { default as MainLayoutCustomer } from '../components/MainLayoutRedesign/MainLayout';
import { ROLE_SUPERCUSTOMER, ROLE_CUSTOMER, ROLE_SUPERTAS, ROLE_TAS } from '@nexxus/constants';
import { useEffect, useRef } from 'react';
import { ErrorBoundaryApp, WidgetWrapper } from '@nexxus/components';
import { PATH_ROLE, ROLE_HUMAN_RESOURCES } from 'constants/users';
import { useGetCurrentUserQuery, useGetCustomersPendingFeedbackQuery } from 'api/api';

const ErrorFallback = (error, resetErrorBoundary, props) => {
  /*const [boundaryContactFlag, setBoundaryContactFlag] = useState(false);*/
  const navigate = useNavigate();
  const {
    auth: { user },
  } = props;

  const location = useLocation();
  const errorLocation = useRef(location.pathname);

  const { data: currentUser, isSuccess } = useGetCurrentUserQuery();
  const { data: customerPendingFeedback } = useGetCustomersPendingFeedbackQuery(
    {
      customerId: currentUser?.Client?.id,
    },
    {
      skip: !isSuccess,
    }
  );

  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary();
    }
  }, [location.pathname, resetErrorBoundary]);

  const handleNavigate = () => navigate(`/${PATH_ROLE(user.role)}/dashboard`);

  const handleContactSupport = () => window.open('mailto:support@sonatafy.com?subject=Contact Support&body=');

  return [ROLE_SUPERCUSTOMER, ROLE_CUSTOMER, ROLE_HUMAN_RESOURCES, ROLE_SUPERTAS, ROLE_TAS].includes(user.role) ? (
    <MainLayoutCustomer
      /*boundaryContactFlag={boundaryContactFlag}*/
      /*setBoundaryContactFlag={setBoundaryContactFlag}*/
      {...props}
      current="dashboard"
      avatarTopBar={props?.auth?.currentUser?.Client?.logoUrl ?? ''}
      feedbackMissed={customerPendingFeedback?.totalCandidates ?? ''}
      handleContactSupport={handleContactSupport}
    >
      <Box>
        <Grid container mt={'20px'} spacing={2}>
          <Grid item xs={12}>
            <WidgetWrapper>
              <ErrorBoundaryApp
                mustHaveContact={true}
                /*setBoundaryContactFlag={setBoundaryContactFlag}*/
                handleContactSupport={handleContactSupport}
                onHandleNavigate={handleNavigate}
              />
            </WidgetWrapper>
          </Grid>
        </Grid>
      </Box>
    </MainLayoutCustomer>
  ) : (
    <MainLayout {...props} current="dashboard">
      <ErrorBoundaryApp
        mustHaveContact={false}
        onHandleNavigate={handleNavigate}
        /*setBoundaryContactFlag={setBoundaryContactFlag}*/
      />
    </MainLayout>
  );
};

const AppRouter = (props) => {
  const handleError = (error, _info) => {
    // toast.error(error?.message);
  };
  const handleFallback = ({ error, resetErrorBoundary }) => {
    return ErrorFallback(error, resetErrorBoundary, props);
  };
  return (
    <Router history={history}>
      <ErrorBoundary FallbackComponent={handleFallback} onError={handleError}>
        <ScrollToTop />
        <RouterApp auth={props.auth} />
      </ErrorBoundary>
    </Router>
  );
};

const RouterApp = (props) => {
  //@ts-ignore
  // #TODO  Revisit all the sections for account manager */
  return (
    <Routes>
      {AuthRoutes}
      {AdminRoutes()}
      {/* Commentd out per NEX-865 */}
      {ImportRoutes()}
      {props.auth?.user?.role === ROLE_HUMAN_RESOURCES ? HumanResourcesRoutes() : TasRoutes()}
      {CustomerRoutes()}
      {AccountManagagerRoutes(props.auth)}
      {CandidateRoutes()}
      {MeRoutes()}
      {AuditTrailRoutes()}
      <Route
        path="/403"
        element={
          <PrivateRoute>
            <Page403 />
          </PrivateRoute>
        }
      />
      <Route path="/sandbox" element={<Sandbox />} />
      <Route path="/main" element={<Layout />} />
      <Route
        path="/404"
        element={
          <PrivateRoute>
            <Page404 />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
