import { useCallback, useEffect, useMemo, useState } from 'react';

import { connect } from 'react-redux';
import { loginExpire } from '../../../store/auth';

import ContainerView from '../../../components/ContainerView';
import MainLayout from '../../../components/MainLayoutRedesign/TasMainLayout';

import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import { Box, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import ManageClientsHeader from './manage/ManageClientHeader';
import ManageCustomerTable from './manage/ManageCustomerTable';
import ManageRenderCompany from './manage/ManageRenderCompany';
import { useGetCustomersQuery } from 'api/api';
import { v4 as uuidv4 } from 'uuid';

const ManageClients = (props) => {
  const [clients, setClients] = useState([]);
  const [hideArchiveCustomers, setHideArchiveCustomers] = useState(false);
  const { data, isLoading } = useGetCustomersQuery();
  const [customLoader, setCustomLoader] = useState(false);

  const [sorting, setSorting] = useState({ sort: 'companyName', order: 'asc' });

  const applySorting = useCallback(
    (data) => {
      function orderingDate(first, second) {
        return new Date(first).getTime() - new Date(second).getTime();
      }
      return data.slice().sort((a, b) => {
        if (['createdAt', 'updatedAt'].includes(sorting.sort))
          return sorting.order === 'asc'
            ? orderingDate(a[sorting.sort], b[sorting.sort])
            : orderingDate(b[sorting.sort], a[sorting.sort]);
        else
          return sorting.order === 'asc'
            ? a.companyName.localeCompare(b.companyName)
            : b.companyName.localeCompare(a.companyName);
      });
    },
    [sorting]
  );

  useMemo(() => {
    if (data?.length > 0) {
      const sorting = applySorting(data);
      setClients(sorting);
    }
  }, [applySorting, data]);

  useMemo(() => {
    if (clients.length > 0) {
      let timeout;

      timeout = setTimeout(() => {
        setCustomLoader(false);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [clients]);

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
        url: '/tas/dashboard',
      },
      {
        name: 'Customers',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="customers" subtitle="Manage Customers">
      <ContainerView>
        <>
          <Box>
            <Box sx={{ display: 'flex', marginBottom: 1 }}>
              <Typography
                variant="h4"
                sx={{
                  textTransform: 'uppercase',
                }}
              >
                MANAGE <strong>CUSTOMERS</strong>
              </Typography>
            </Box>
            <ManageClientsHeader
              role={props.auth.user.role}
              hideArchiveCustomers={hideArchiveCustomers}
              setHideArchiveCustomers={setHideArchiveCustomers}
            />
            <Box sx={{ borderColor: 'nexxusGrayLight.main', borderWidth: 1, borderStyle: 'solid', px: 4, py: 2 }}>
              <ManageCustomerTable
                user={props.auth.user}
                sorting={sorting}
                setSorting={setSorting}
                setCustomLoader={setCustomLoader}
              >
                {!isLoading && clients.length > 0 && !customLoader
                  ? clients.map((client) => {
                      return (
                        <ManageRenderCompany
                          key={`render-company-${client.id}`}
                          client={client}
                          user={props.auth.user}
                          hideArchive={hideArchiveCustomers}
                        />
                      );
                    })
                  : [...new Array(10)].map((_e) => (
                      <TableRow key={uuidv4()} sx={{}}>
                        <TableCell sx={{ padding: 0 }} colSpan={8}>
                          <Skeleton variant="rectangular" animation="wave" sx={{ width: '100%', mb: 2 }} height={120} />
                        </TableCell>
                      </TableRow>
                    ))}
              </ManageCustomerTable>
            </Box>
          </Box>
          {/* <ArchiveClientDialog {...archiveClientDialog} /> */}
        </>
      </ContainerView>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageClients);
