import { Box, Switch, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Edit as EditIcon } from '@mui/icons-material';
import { useUpdateManagedCountryMutation, useUpdatePhoneAvailabilityMutation } from 'api/api';
import { CircularProgressMUI } from 'stories/playground/mui/CircularProgress.stories';
import { useEffect, useState } from 'react';

const dataGridStyles = {
  '&': {
    padding: '0 !important',
    margin: '0 !important',
    border: 'none !important',
  },
  '& .MuiDataGrid-main': {
    margin: 0,
    padding: 0,
  },
  '& .MuiDataGrid-cell': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    border: 'none',
    margin: 0,
    padding: 0,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: '#656565 !important',
  },
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: '#f0f0f0', // color for odd rows
  },
};

const ManagedCountryList = ({ managedCountries, onSelectCountry }) => {
  const [updateManagedCountryMutation] = useUpdateManagedCountryMutation();
  const [updatePhoneAvailability] = useUpdatePhoneAvailabilityMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (managedCountries?.length) {
      setRows(managedCountries);
    }
  }, [JSON.stringify(managedCountries)]);

  const handleIsHireAvailableUpdate = (id, checked) => {
    setIsLoading(true);
    updateManagedCountryMutation({
      CountryId: id,
      data: {
        isHireAvailable: checked,
      },
    })
      .unwrap()
      .then(() => {
        const updateRows = rows.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              CountryAvailability: {
                ...item.CountryAvailability,
                isHireAvailable: checked,
              },
            };
          }
          return item;
        });
        setRows(updateRows);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  const onUpdatePhoneAvailability = (id, checked) => {
    setIsLoading(true);
    updatePhoneAvailability({
      CountryId: id,
      data: {
        isActive: checked,
      },
    })
      .unwrap()
      .then(() => {
        const updateRows = rows.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              CountryAvailability: {
                ...item.CountryAvailability,
                isPhoneAvailable: checked,
              },
            };
          }
          return item;
        });
        setRows(updateRows);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  const columns = [
    { field: 'name', headerName: 'Country', width: 130, headerAlign: 'left' },
    {
      field: 'CountryAvailability.comments',
      headerName: 'Comment',
      flex: 1,
      width: 230,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            {row.CountryAvailability.comments}
          </div>
        );
      },
    },
    {
      headerName: 'Whitelist Phone',
      width: 140,
      renderCell: ({ row }) => {
        return (
          <Box display={'flex'} alignItems={'center'}>
            {!!row.CountryAvailability.isPhoneAvailable && <Typography color={'#979797'}>OFF</Typography>}
            <Switch
              defaultChecked={!row.CountryAvailability.isPhoneAvailable}
              onChange={(_, checked) => onUpdatePhoneAvailability(row.id, !checked)}
            />
            {!row.CountryAvailability.isPhoneAvailable && <Typography color={'#004DD3'}>ON</Typography>}
          </Box>
        );
      },
    },
    {
      field: 'CountryAvailability.isHireAvailable',
      headerName: 'Blacklisted',
      renderCell: ({ row }) => {
        return (
          <Box display={'flex'} alignItems={'center'}>
            {!!row.CountryAvailability.isHireAvailable && <Typography color={'#979797'}>OFF</Typography>}
            <Switch
              defaultChecked={!row.CountryAvailability.isHireAvailable}
              onChange={(_, checked) => handleIsHireAvailableUpdate(row.id, !checked)}
            />
            {!row.CountryAvailability.isHireAvailable && <Typography color={'#004DD3'}>ON</Typography>}
          </Box>
        );
      },
      width: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Edit',
      headerAlign: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          key="edit"
          icon={<EditIcon sx={{ color: '#004DD3' }} />}
          onClick={() => {
            onSelectCountry(params.row);
          }}
          label="Edit"
        />,
      ],
    },
  ];

  if (isLoading) {
    return (
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={5}>
        <CircularProgressMUI />
      </Box>
    );
  }

  return (
    <Box minHeight={400} mt={2}>
      <DataGrid
        sx={dataGridStyles}
        disableColumnMenu
        disableSelectionOnClick
        density="compact"
        rows={rows}
        rowsPerPageOptions={[]}
        // @ts-ignore
        columns={columns}
        hideFooterPagination={true}
        autoHeight={true}
      />
    </Box>
  );
};

export default ManagedCountryList;
