import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { useMemo } from 'react';

const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  fontWeight: '500',
  paddingTop: 1,
  textTransform: 'uppercase',
  textAlign: 'center',
  color: theme.palette.nexxusGrayLight.main,
}));
const TitleTypography = styled(Typography)({
  fontSize: 18,
  fontWeight: '800',
  paddingBottom: 1,
  textTransform: 'uppercase',
  textAlign: 'center',
});

const CustomBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

export default function CandidateInfo(props) {
  const theme = useTheme();
  const lineDivisor = `1.2px solid ${theme?.palette?.nexxusGrayLight?.main}`;

  const colorEnglishProficiency = () => {
    if (props.candidate.CandidateGrade?.length > 0) {
      if (props.candidate.CandidateGrade[0]?.english_proficiency === 5) {
        return theme.palette.success.main;
      }
      if (props.candidate.CandidateGrade[0]?.english_proficiency <= 4) {
        return '#EF9148';
      }
      if (props.candidate.CandidateGrade[0]?.english_proficiency <= 2) {
        return theme.palette.error.main;
      } else {
        return '#000000B5';
      }
    }
  };

  const locationCandidate = useMemo(() => {
    return (
      props.locations
        .find((loc) => loc.id === props.candidate.LocationCountryId)
        ?.name?.substring(0, 3)
        ?.toUpperCase() ?? ''
    );
  }, [props.candidate.LocationCountryId, props.locations]);

  return (
    <>
      <Box sx={{ flexGrow: 1, mt: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={6} sx={{ borderRight: lineDivisor, borderBottom: lineDivisor }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <HeaderTypography>Location:</HeaderTypography>
              <TitleTypography sx={{ color: 'nexxusBlack.main' }}>{locationCandidate}</TitleTypography>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ borderBottom: lineDivisor }}>
            <CustomBox>
              <HeaderTypography>English Proficiency:</HeaderTypography>
              <TitleTypography sx={{ color: colorEnglishProficiency() }}>
                {props.candidate.CandidateGrade &&
                props.candidate.CandidateGrade.length > 0 &&
                props.candidate.CandidateGrade[0].english_proficiency !== undefined ? (
                  <>{props.candidate.CandidateGrade[0].english_proficiency}</>
                ) : (
                  <>N/A</>
                )}
              </TitleTypography>
            </CustomBox>
          </Grid>
          <Grid item xs={6} sx={{ borderRight: lineDivisor }}>
            <CustomBox>
              <HeaderTypography>Code Test Score:</HeaderTypography>
              {props.candidate.candidate_code_score ? (
                <TitleTypography sx={{ color: 'info.main' }}>{props.candidate?.candidate_code_score}%</TitleTypography>
              ) : (
                <TitleTypography sx={{ color: 'info.main' }}>N/A</TitleTypography>
              )}
            </CustomBox>
          </Grid>
          <Grid item xs={6}>
            <CustomBox>
              <HeaderTypography>Time Zone:</HeaderTypography>
              <TitleTypography sx={{ color: 'nexxusBlack.main' }}>{props.candidate.timezone}</TitleTypography>
            </CustomBox>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
