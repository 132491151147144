import { useGetCountriesQuery, useAddManagedCountryMutation, useUpdateManagedCountryMutation } from 'api/api';
import { useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Button, Stack, Autocomplete, TextField } from '@mui/material';
import { InputTextOutlined } from '@nexxus/components';
import { toast } from 'react-toastify';

const schema = Joi.object({
  CountryId: Joi.number().required().label('Country').messages({
    'number.base': 'Country is required',
    'any.required': 'Country is required',
  }),
  comments: Joi.string().allow('').label('Comments'),
  code: Joi.optional()
});

const CountryAvailabilityForm = ({ country, managedCountries, onSubmit, onCancel }) => {
  const { data: countries } = useGetCountriesQuery();
  const [countryOptions, setCountryOptions] = useState([]);

  const [addManagedCountryMutation] = useAddManagedCountryMutation();
  const [updateManagedCountryMutation] = useUpdateManagedCountryMutation();

  const initialDefaultValues = {
    CountryId: null,
    comments: '',
    code: ''
  };

  const [defaultValues, setDefaultValues] = useState(initialDefaultValues);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues,
  });

  const onSubmitHandler = (data) => {
    if (country) {
      updateManagedCountryMutation({
        CountryId: data.CountryId,
        data,
      })
        .unwrap()
        .then(() => {
          onSubmit(data);
          toast.success('Country updated successfully');
          reset();
        })
        .catch((err) => {
          toast.error(err.data.message);
        });
    } else {
      addManagedCountryMutation(data)
        .unwrap()
        .then(() => {
          onSubmit(data);
          toast.success('Country added successfully');
          reset();
        })
        .catch((err) => {
          toast.error(err.data.message);
        });
    }
  };

  useEffect(() => {
    // SET COUNTRY OPTIONS
    if (managedCountries) {
      let options = countries;

      if (!country) {
        options = countries?.filter(
          (country) => !managedCountries?.find((managedCountry) => managedCountry.id === country?.id)
        );
      }

      // if country in managed countries set a disabled option to true
      // options = options.map((option) => {
      //   const managedCountry = managedCountries?.find((managedCountry) => managedCountry.id === option?.id);
      //   if (managedCountry) {
      //     return {
      //       ...option,
      //       disabled: true,
      //     };
      //   }
      //   return option;
      // });

      setCountryOptions(options);
    }
  }, [countries, managedCountries, country]);

  useEffect(() => {
    setDefaultValues({
      CountryId: country?.id ?? null,
      comments: country?.CountryAvailability.comments ?? '',
      code: country?.code || ''
    });
  }, [country]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Stack gap={2} direction="column" component="form" onSubmit={handleSubmit(onSubmitHandler)}>
      <Controller
        name="CountryId"
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Autocomplete
            options={countryOptions ?? []}
            disabled={!!country}
            renderInput={(params) => (
              <TextField
                error={!!errors?.CountryId}
                helperText={errors?.CountryId?.message.toString()}
                {...params}
                inputRef={ref}
              />
            )}
            value={
              value
                ? countryOptions.find((option) => {
                    return value === option.id;
                  }) ?? null
                : null
            }
            getOptionLabel={(option) => {
              return option.name;
            }}
            // getOptionDisabled={(option) => {
            //   return !!option.disabled;
            // }}
            // groupBy={(option) => option.disabled}
            onChange={(event, newValue) => {
              onChange(newValue ? newValue.id : null);
            }}
          />
        )}
      />

      <Controller
        name="comments"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <InputTextOutlined
            type="text"
            id={name}
            multiline={true}
            rowsMultiline={4}
            onChangeInput={onChange}
            initialValue={value}
            error={errors?.comments?.message}
            placeholder="Add reason/commentary"
          />
        )}
      />

      {!country ? (
        <Button type="submit" variant="outlined" sx={{ color: 'white', background: '#1841A3'}} fullWidth>
          SAVE
        </Button>
      ) : (
        <Stack gap={2} direction="row-reverse">
          <Button type="submit" variant="contained" sx={{ color: 'white', background: '#1841A3'}} fullWidth>
            SAVE
          </Button>
          <Button onClick={() => onCancel()} variant="outlined" color="primary" fullWidth>
            CANCEL
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default CountryAvailabilityForm;
