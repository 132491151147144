import Box from '@mui/material/Box';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';

import { Chip, FormControl, FormLabel, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import { PROJECT_HOLIDAYS, PROJECT_HARDWARE, PROJECT_SKILL_YEAR_EXP, PROJECT_PRIORITY } from 'constants/project';
import _ from 'lodash';
import { seniority, timezoneOpts } from 'utils/staticData';
import { currencyFormat } from 'utils/utils';
import { PROJECT_STATUS } from 'constants/project';
import InputFileWithText from 'components/FormElement/InputFileWithText';
import { POSITIONS } from '@nexxus/constants';

const ProjectFormView = ({ recruiters, projectDB }) => {
  const theme = useTheme();
  const [project, setProject] = useState({});

  // project hook
  useEffect(() => {
    (async () => {
      if (!_.isEmpty(projectDB)) {
        const senioritySelected = seniority.find((s) => s._id === projectDB.seniority);
        const recruiter = recruiters.find((rec) => rec.id === projectDB.RecruiterId);
        const statusDB = Object.keys(PROJECT_STATUS).find((key) => PROJECT_STATUS[key] === projectDB.status);
        const timezone = timezoneOpts.find((tz) => tz._id === projectDB.timezone);
        const hardware = Object.values(projectDB.hardware_needs);

        const hardware_label = PROJECT_HARDWARE.filter((h) => hardware.includes(h._id)).map((obj) => obj.label);
        const allSkills = projectDB.SkillsDB.map((sk) => {
          const yoe = PROJECT_SKILL_YEAR_EXP.find((y) => y._id === sk.year_experience);
          // @ts-ignore
          return { name: sk.Skill.name, experience: yoe ? yoe.label?.replaceAll(' ', '') : '', type: sk.skill_type };
        });
        const skill = await allSkills.filter((sk) => sk.type === 3 || sk.type === 1);
        const aditionalSkill = await allSkills.filter((sk) => sk.type === 2);

        const initialHour = '06:00';
        let hoursStart;
        let hoursEnd;
        for (let i = 0; i <= 14; i++) {
          //tas->projects/number/view
          const time = moment(initialHour, 'hh').add(i, 'hours');
          const formatDate = await time.format('HH');
          const workingHourMondStart = await projectDB.working_hours.monday.start;
          const workingHourMondEnd = await projectDB.working_hours.monday.end;
          hoursStart = getHoursInfo(formatDate, workingHourMondStart) ?? hoursStart;
          hoursEnd = getHoursInfo(formatDate, workingHourMondEnd) ?? hoursEnd;
        }

        const holiday = PROJECT_HOLIDAYS.filter((h) => h._id === projectDB.holidays).map((obj) => obj.label);

        const newData = {
          name: projectDB.name,
          description: projectDB.description,
          position: projectDB.position,
          seniority: senioritySelected.label,
          ClientId: projectDB.ClientId.toString(),
          status: statusDB,
          teamsize: projectDB.teamsize,
          RecruiterId: recruiter?.full_name || '',
          managers: projectDB.managers,
          timezone: timezone?.label || '',
          customer_job_link: projectDB.customer_job_link || '',
          customer_job_description_link: projectDB.customer_job_description_link || '',
          codetest_link: projectDB.codetest_link || '',
          maxrate: currencyFormat.format(projectDB?.maxrate),
          monthly_mxn: currencyFormat.format(projectDB?.monthly_mxn),
          monthly_usd: currencyFormat.format(projectDB?.monthly_usd),
          rate_assumption: projectDB.rate_assumption,
          codetest_id: projectDB.codetest_id,
          notes: projectDB.notes,
          priority: projectDB.priority,
          job_description_link: projectDB.job_description_link,
          hardware_needs: hardware_label[0] || '',
          hardware_notes: projectDB.hardware_notes,
          interview_process: projectDB.interview_process,
          holidays: holiday[0] || '',
          skills: skill, // multiple
          adittionalSkills: aditionalSkill, // array single
          working_hours: {
            monday: {
              active: projectDB.working_hours.monday.active,
              start: `${hoursStart}:00`,
              end: `${hoursEnd}:00`,
            },
          },
        };
        setProject(newData);
      }
    })();
    const getHoursInfo = (time, comparativeWorkingHour) => {
      if (time === comparativeWorkingHour) return time;
    };
  }, [projectDB]); // eslint-disable-line

  return (
    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
      <Grid item xs={12} sm={6}>
        <InputTextOutlinedV2
          id={'name'}
          isDisabled={true}
          label="POSITION"
          initialValue={project.position ? Object.values(POSITIONS).find((p) => p._id === project.position)?.label : ''}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <InputTextOutlinedV2 id={'seniority'} isDisabled={true} label="SENIORITY" initialValue={project.seniority} />
      </Grid>
      <Grid item xs={6} sm={3}>
        <InputTextOutlinedV2 id={'RecruiterId'} isDisabled={true} label="RECRUITER" initialValue={project.RecruiterId} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputTextOutlinedV2
          id={'description'}
          isDisabled={true}
          label="PROJECT DESCRIPTION"
          multiline
          rowsMultiline={4}
          initialValue={project.description}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputTextOutlinedV2
          id={'notes'}
          isDisabled={true}
          label="PROJECT NOTES"
          multiline
          rowsMultiline={4}
          initialValue={project.notes}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <InputTextOutlinedV2
          id={'interview_process'}
          isDisabled={true}
          label="CUSTOMER INTERVIEW PROCESS"
          multiline
          rowsMultiline={4}
          initialValue={project.interview_process}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputTextOutlinedV2
          id={'job_description_link'}
          isDisabled={true}
          label="JOB DESCRIPTION INFO"
          multiline
          rowsMultiline={4}
          initialValue={project.job_description_link}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <InputTextOutlinedV2
          id={'customer_job_description_link'}
          initialValue={project.customer_job_description_link}
          label="JOB DESCRIPTION LINK"
          isDisabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        {/* <InputTextOutlinedV2
          id={'customer_job_link'}
          isDisabled={true}
          label="JOB DESCRIPTION FILE"
          initialValue={project.customer_job_link}
        /> */}
        <InputFileWithText
          accept={['application/pdf']}
          id={'customer_job_link'}
          label={'Job Description File'}
          onFileChange={() => {}}
          reset={false}
          defaultFileName={project.customer_job_link}
          isDisabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <InputTextOutlinedV2
          id={'hardware_needs'}
          isDisabled={true}
          label="HARDWARE NEEDS"
          initialValue={project.hardware_needs}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <InputTextOutlinedV2
          id={'hardware_notes'}
          isDisabled={true}
          label="HARDWARE NOTES"
          initialValue={project.hardware_notes}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} sm={12}>
            <InputTextOutlinedV2
              id={'priority'}
              isDisabled={true}
              label="PRIORITY"
              initialValue={PROJECT_PRIORITY.find((p) => p._id === project.priority)?.label}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputTextOutlinedV2 id={'headcount'} isDisabled={true} label="HEADCOUNT" initialValue={project.teamsize} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputTextOutlinedV2 id={'timezone'} isDisabled={true} label="TIMEZONE" initialValue={project.timezone} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputTextOutlinedV2
              id={'codetest_link'}
              isDisabled={true}
              label="CODE TEST - INSERT LINK"
              initialValue={project.codetest_link}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputTextOutlinedV2
              id={'day_monday_from'}
              isDisabled={true}
              label="WORKING HOURS FROM"
              initialValue={project.working_hours?.monday.start}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputTextOutlinedV2
              id={'day_monday_to'}
              label="WORKING HOURS TO"
              isDisabled={true}
              initialValue={project.working_hours?.monday.end}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputTextOutlinedV2 id={'holidays'} isDisabled={true} label="HOLIDAYS" initialValue={project.holidays} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputTextOutlinedV2 id={'maxrate'} isDisabled={true} label="MAX CUSTOMER RATE" initialValue={project.maxrate} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputTextOutlinedV2
              id={'codetest_id'}
              isDisabled={true}
              label="CODE TEST ID"
              initialValue={project.codetest_id}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mt={4} sx={{ backgroundColor: '#fafafa' }}>
          <Grid container spacing={2} padding={2} alignItems="flex-start">
            <Grid item xs={12}>
              <Typography
                sx={{
                  marginTop: -1,
                  marginBotom: 10,
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  color: '#666',
                  fontWeight: 'bold',
                  fontSize: 20,
                }}
              >
                Project Candidate Info
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12}>
                  <InputTextOutlinedV2
                    id={'monthly_mxn'}
                    placeholder="MXN"
                    label="MO. RATE (MXN)"
                    initialValue={project.monthly_mxn}
                    isDisabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputTextOutlinedV2
                    id={'monthly_usd'}
                    placeholder="USD"
                    label="MO. RATE (USD)"
                    initialValue={project.monthly_usd}
                    isDisabled={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8}>
              <InputTextOutlinedV2
                id={'rate_assumption'}
                initialValue={project.rate_assumption}
                label="Candidate Rate Assumptions"
                multiline
                rowsMultiline={5}
                isDisabled={true}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ textAlign: 'left' }}>
              <FormLabel>PRIMARY SKILLS (+ YOE)</FormLabel>
              <Box
                sx={{
                  width: '100%',
                  minHeight: '148px',
                  borderRadius: theme.variables.inputs.borderRadius,
                  borderColor: theme.variables.inputs.borderColor,
                  borderWidth: theme.variables.inputs.borderWidth,
                  borderStyle: 'solid',
                }}
              >
                {project.skills &&
                  project.skills.length > 0 &&
                  project.skills.map((skill, index) => (
                    <Chip
                      key={index}
                      sx={{ marginLeft: 1, marginTop: 1 }}
                      label={`${skill.name} (${skill.experience})`}
                      variant="select-tag"
                      primary={skill.type === 3 ? 'true' : 'false'}
                    />
                  ))}
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ textAlign: 'left' }}>
              <FormLabel>ADDITIONAL SKILLS (+ YOE)</FormLabel>
              <Box
                sx={{
                  width: '100%',
                  minHeight: '148px',
                  borderRadius: theme.variables.inputs.borderRadius,
                  borderColor: theme.variables.inputs.borderColor,
                  borderWidth: theme.variables.inputs.borderWidth,
                  borderStyle: 'solid',
                }}
              >
                {project.adittionalSkills &&
                  project.adittionalSkills.map((skill, index) => (
                    <Chip
                      key={index}
                      sx={{ marginLeft: 1, marginTop: 1 }}
                      label={`${skill.name} (${skill.experience})`}
                      variant="select-tag"
                    />
                  ))}
              </Box>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {project.managers &&
              project.managers.map((manager, index) => (
                <Grid key={index} container spacing={2} sx={{ marginBottom: 2 }}>
                  <Grid item xs={6}>
                    <InputTextOutlinedV2
                      id={`managers_${index}_name`}
                      isDisabled={true}
                      label="HIRING MANAGER"
                      initialValue={manager.name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputTextOutlinedV2
                      id={`managers_${index}_email`}
                      isDisabled={true}
                      label="MANAGER EMAIL"
                      initialValue={manager.email}
                    />
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFormView);
